import NewWindowLink from 'lib/display_helpers/url_links';
import { thirdPartyUrls } from 'lib/urls';
import './lp_legal_terms_footer.scss';

const LpLegalTermsFooter = () => {
  const browserDate = new Date();

  return (
    <div className="lp-legal-terms-footer">
      <div>&copy; {browserDate.getFullYear()} Tempo Inc. All Rights Reserved.</div>
      <div>
        <NewWindowLink className="lp-legal-terms-footer__tos" to={thirdPartyUrls.termsOfService}>
          Terms of Service
        </NewWindowLink>
        <NewWindowLink to={thirdPartyUrls.privacyPolicy}>Privacy Policy</NewWindowLink>
      </div>
    </div>
  );
};

export default LpLegalTermsFooter;
