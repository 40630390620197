import { PmLegalTermsFooter } from 'containers/shared/footers/pm_legal_terms_footer';
import { UnauthenticatedHeader } from 'containers/shared/headers/unauthenticated_header';
import { UnauthenticatedTitles, PageNames } from 'containers/shared/headers/unauthenticated_titles';
import LpLink from 'containers/shared/lp_link';
import { ForgotPasswordForm } from 'features/authentication/unauthenticated/forgot_password_v2/form';
import { frontend } from 'lib/urls';

import './index.scss';

export const ForgotPasswordV2 = () => (
  <div className="pm-forgot-password">
    <UnauthenticatedHeader className="pm-forgot-password__header" />
    <div className="pm-forgot-password__steps">
      <div className="steps__back-action">
        <LpLink to={frontend.login.url({})}>← Back</LpLink>
      </div>
      <div className="steps__content">
        <UnauthenticatedTitles className="steps__titles" pageName={PageNames.FORGOT_PASSWORD} />
        <ForgotPasswordForm />
      </div>
    </div>
    <PmLegalTermsFooter className="pm-signup__footer" />
  </div>
);
