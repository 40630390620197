import { Table } from 'semantic-ui-react';

import { ListWidgetType } from 'daos/enums';
import { WidgetWithData } from 'daos/model_types';
import { WidgetConfigColumn } from 'daos/widget_column';
import { getFilteredWidgetColumnsOnBenchmarkEstimate } from 'features/common/data_grid/helpers';
import { ItemListWidgetData, PeopleListWidgetData } from 'features/dashboards_v2/widget/widgets/reports/types';
import { useHasFeature } from 'hooks/use_has_feature';
import { FeatureFlag } from 'lib/feature_flags';

import { ListTableHeader } from './header_row';
import { ItemListTableRow, PeopleListTableRow, SummaryTableRow } from './row';

const ItemListBody = ({
  columnDefinitions,
  widget,
}: {
  columnDefinitions: ReadonlyArray<WidgetConfigColumn>;
  widget: WidgetWithData;
}) => {
  const data = widget.data as ItemListWidgetData;

  return (
    <Table.Body>
      {data.rowData.map((itemRow) => (
        <ItemListTableRow
          key={itemRow.itemId}
          itemRow={itemRow}
          columnDefinitions={columnDefinitions}
          widget={widget}
        />
      ))}

      {widget.config.showTotals && data.totalRowData && (
        <SummaryTableRow summaryData={data.totalRowData} columnDefinitions={columnDefinitions} />
      )}
    </Table.Body>
  );
};

const PeopleListBody = ({
  columnDefinitions,
  widget,
}: {
  columnDefinitions: ReadonlyArray<WidgetConfigColumn>;
  widget: WidgetWithData;
}) => {
  const data = widget.data as PeopleListWidgetData;

  return (
    <Table.Body>
      {data.rowData.map((peopleRow) => (
        <PeopleListTableRow
          key={peopleRow.user.userId}
          peopleRow={peopleRow}
          columnDefinitions={columnDefinitions}
          widget={widget}
        />
      ))}
      {widget.config.showTotals && data.totalRowData && (
        <SummaryTableRow summaryData={data.totalRowData} columnDefinitions={columnDefinitions} />
      )}
    </Table.Body>
  );
};

const TableBody = ({
  columnDefinitions,
  widget,
}: {
  columnDefinitions: ReadonlyArray<WidgetConfigColumn>;
  widget: WidgetWithData;
}) => {
  switch (widget.config.listType) {
    case ListWidgetType.Assignment:
    case ListWidgetType.Folders:
    case ListWidgetType.Packages:
    case ListWidgetType.Projects:
    case ListWidgetType.Tasks:
      return <ItemListBody widget={widget} columnDefinitions={columnDefinitions} />;

    case ListWidgetType.Users: {
      return <PeopleListBody widget={widget} columnDefinitions={columnDefinitions} />;
    }

    case ListWidgetType.Picklists:
    default:
      return null;
  }
};

export const ListWidgetTable = ({ widget }: { widget: WidgetWithData }) => {
  const { config } = widget;
  const hasTeamBasedPlanning = useHasFeature(FeatureFlag.teamBasedPlanning);

  const previewColumnsLimit = config.listPreviewColumns ?? 0;
  const columns = config.columns ?? [];
  const featureAwareColumns = getFilteredWidgetColumnsOnBenchmarkEstimate(columns, hasTeamBasedPlanning);
  const columnDefinitions = getPreviewDisplayColumns(featureAwareColumns, previewColumnsLimit);

  return (
    <Table basic="very" compact singleLine>
      <ListTableHeader config={widget.config} previewColumns={columnDefinitions} />
      <TableBody widget={widget} columnDefinitions={columnDefinitions} />
    </Table>
  );
};

function getPreviewDisplayColumns(initialColumns: ReadonlyArray<WidgetConfigColumn>, previewColumnLimit: number) {
  return [...initialColumns].slice(0, previewColumnLimit);
}
