export const JiraFeature = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      d="M15 3H5C3.89543 3 3 3.89543 3 5V15C3 16.1046 3.89543 17 5 17H15C16.1046 17 17 16.1046 17 15V5C17 3.89543 16.1046 3 15 3Z"
      fill="#FF9C23"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.4917 14C8.9397 14 8.4917 13.552 8.4917 13V11H11.4917V13C11.4917 13.552 11.0437 14 10.4917 14H9.4917Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.49 12.553H10.49V11.74C10.492 11.627 10.501 11.522 10.521 11.421C10.587 11.075 10.776 10.815 10.963 10.577L11.205 10.28C11.308 10.154 11.412 10.029 11.51 9.89897C11.921 9.35897 12.062 8.78197 11.941 8.13497C11.772 7.23097 10.937 6.54697 9.99899 6.54297C9.04599 6.54697 8.21099 7.23097 8.04199 8.13497C7.92199 8.78197 8.06299 9.35897 8.47299 9.89897C8.57299 10.03 8.67699 10.157 8.78199 10.284L9.01999 10.578C9.23599 10.844 9.43399 11.09 9.49799 11.422C9.51699 11.523 9.52599 11.629 9.52799 11.733V12.553H8.52799V11.74C8.52699 11.701 8.52399 11.656 8.51599 11.611C8.49899 11.524 8.39199 11.392 8.29899 11.276L8.00999 10.919C7.89699 10.782 7.78499 10.646 7.67699 10.505C7.09399 9.73797 6.88599 8.87897 7.05899 7.95097C7.31799 6.56097 8.54699 5.54897 9.97999 5.54297C11.436 5.54897 12.665 6.56097 12.924 7.95097C13.097 8.87897 12.889 9.73797 12.306 10.505C12.2 10.644 12.089 10.78 11.977 10.915L11.748 11.198C11.632 11.344 11.526 11.485 11.503 11.61C11.494 11.657 11.491 11.703 11.49 11.749V12.553Z"
      fill="white"
    />
  </svg>
);
