import './unauthenticated_titles.scss';

export enum PageNames {
  SIGNUP = 'signup',
  SIGNUP_STEP_2 = 'signup-step-2',
  LOGIN = 'login',
  LOGIN_SSO = 'login-sso',
  FORGOT_PASSWORD = 'forgot-password',
  FORGOT_PASSWORD_SENT = 'forgot-password-sent',
  PASSWORD_RESET = 'password-reset',
  EMAIL_CONFIRMATION_EXPIRED = 'email-confirmation-expired',
  EMAIL_CONFIRMATION_ERROR = 'email-confirmation-error',
}

interface Titles {
  title: string;
  subtitle?: {
    text: string;
    style?: string;
  };
}

const sharedTitles: Titles = {
  title: 'Control project risk and deliver on time',
  subtitle: {
    text: 'Fields marked with * are mandatory.',
    style: 'italic',
  },
};

const unauthenticatedTitlesByPageName: Record<PageNames, typeof sharedTitles> = {
  [PageNames.LOGIN]: sharedTitles,
  [PageNames.LOGIN_SSO]: sharedTitles,
  [PageNames.SIGNUP]: sharedTitles,
  [PageNames.SIGNUP_STEP_2]: {
    title: 'Tell us about yourself',
    subtitle: { text: "We'll customize your experience based on your choice." },
  },
  [PageNames.FORGOT_PASSWORD]: {
    title: "Forgot your password? No problem!\nWe'll send you a recovery link.",
  },
  [PageNames.FORGOT_PASSWORD_SENT]: {
    title: 'A recovery link has been sent to the email you provided. Please check your inbox.',
  },
  [PageNames.PASSWORD_RESET]: {
    title: 'A recovery link has been sent to the email you provided. Please check your inbox.',
  },
  [PageNames.EMAIL_CONFIRMATION_EXPIRED]: {
    title: 'Confirmation email has expired. Please request a new confirmation email.',
  },
  [PageNames.EMAIL_CONFIRMATION_ERROR]: {
    title:
      "We're sorry, an error occurred while trying to confirm your email address. Please click the button below to proceed to the app anyway.",
  },
};

interface UnauthenticatedHeaderProps {
  className?: string;
  pageName: PageNames;
}

export const UnauthenticatedTitles = (props: UnauthenticatedHeaderProps) => {
  const { className = '', pageName } = props;
  const { title, subtitle } = unauthenticatedTitlesByPageName[pageName];

  return (
    <div className={`unauthenticated__titles ${className}`}>
      <h3 className="unauthenticated__titles--main">{title}</h3>
      {subtitle && (
        <p
          className={`unauthenticated__titles--secondary ${
            subtitle.style ? `unauthenticated__titles--${subtitle.style}` : ''
          }`}
        >
          {subtitle.text}
        </p>
      )}
    </div>
  );
};
