import { Button, Form } from 'semantic-ui-react';

import LpLink from 'containers/shared/lp_link';
import { RegistrationErrors } from 'features/authentication/unauthenticated/registration_v2/errors';
import LpErrorMessage from 'features/common/errors/lp_error_message';
import LpFormInput from 'features/common/forms/lp_form_input';
import { frontend } from 'lib/urls';

export default function Step1(props: any) {
  const { isSubmitting, handleSubmit, handleDismissApiError, formApiError, getFieldProps, getFieldMeta } = props;

  return (
    <Form className="steps__form" autoComplete="on" onSubmit={handleSubmit} loading={isSubmitting}>
      {formApiError && (
        <LpErrorMessage
          className="form__api-error"
          error={formApiError}
          customError={<RegistrationErrors code={formApiError.code} detail={formApiError.detail} />}
          onDismiss={handleDismissApiError}
        />
      )}
      <LpFormInput
        e2eTestId="businessEmail"
        disableLastPass={false}
        fieldKey="email"
        className="form__input-field"
        label="Email*"
        getFieldProps={getFieldProps}
        getFieldMeta={getFieldMeta}
        placeholder="Example@email.com"
      />
      <LpFormInput
        e2eTestId="signupPassword"
        disableLastPass={false}
        fieldKey="password"
        className="form__input-field"
        label="Password*"
        type="password"
        getFieldProps={getFieldProps}
        getFieldMeta={getFieldMeta}
        placeholder="Create your password"
      />

      <LpFormInput
        e2eTestId="passwordConfirmation"
        disableLastPass={false}
        fieldKey="passwordConfirmation"
        className="form__input-field"
        label="Confirm Password*"
        type="password"
        getFieldProps={getFieldProps}
        getFieldMeta={getFieldMeta}
        placeholder="Confirm your password"
      />
      <Button
        data-e2e-test-id="continueCreateAccount"
        className="form__button"
        content="Continue →"
        type="submit"
        disabled={isSubmitting}
      />
      <div className="form__signup-cta">
        Already have an account?&nbsp;
        <LpLink className="form__signup-link" to={frontend.login.url({})}>
          Log In
        </LpLink>
      </div>
    </Form>
  );
}
