import { MotionProps, TargetAndTransition, Transition, VariantLabels, Variants, motion } from 'framer-motion';
import { ReactNode, forwardRef } from 'react';

import { green200, lpBrandWhite } from 'style/variables';

export const newItemVariants: Variants = {
  initial: { background: lpBrandWhite, opacity: 0 },
  animate: {
    opacity: 1,
  },
  new: {
    opacity: 1,
    background: [lpBrandWhite, green200, green200, lpBrandWhite],
    transition: {
      duration: 0.5,
      ease: [0.4, 0.0, 0.2, 1],
    },
  },
};

export const LpMotionFadeInAndUp = ({ children, className }: { children: ReactNode; className?: string }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 14 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ type: 'spring', delay: 0.3 }}
      className={className}
    >
      {children}
    </motion.div>
  );
};

type LpMotionFadeInProps = {
  children: ReactNode;
  className?: string;
  layout?: boolean;
  transition?: Transition | undefined;
  exit?: VariantLabels | TargetAndTransition | undefined;
} & MotionProps;

export const LpMotionFadeIn = forwardRef<HTMLDivElement, LpMotionFadeInProps>(({ children, ...props }, ref) => {
  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ delay: 0.1 }}
      {...props}
    >
      {children}
    </motion.div>
  );
});
