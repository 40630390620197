export const JiraEpic = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      d="M15 3H5C3.89543 3 3 3.89543 3 5V15C3 16.1046 3.89543 17 5 17H15C16.1046 17 17 16.1046 17 15V5C17 3.89543 16.1046 3 15 3Z"
      fill="#904EE2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.9232 9.75602L12.9212 9.75202C12.9672 9.67702 13.0002 9.59402 13.0002 9.49902C13.0002 9.22302 12.7762 8.99902 12.5002 8.99902H10.0002V6.49902C10.0002 6.22302 9.77624 5.99902 9.50024 5.99902C9.32824 5.99902 9.18524 6.09102 9.09524 6.22202C9.06724 6.26302 9.04424 6.30502 9.02924 6.35202L7.08124 10.236L7.08324 10.239C7.03524 10.316 7.00024 10.402 7.00024 10.499C7.00024 10.776 7.22424 10.999 7.50024 10.999H10.0002V13.499C10.0002 13.776 10.2242 13.999 10.5002 13.999C10.6792 13.999 10.8292 13.9 10.9182 13.758L10.9212 13.759L10.9342 13.733C10.9452 13.712 10.9572 13.693 10.9652 13.671L12.9232 9.75602Z"
      fill="white"
    />
  </svg>
);
