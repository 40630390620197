import LpLink from 'containers/shared/lp_link';
import { frontend } from 'lib/urls';
import portfolioManagerNewLogo from 'static/img/new_logo.png';

import './unauthenticated_header.scss';
interface UnauthenticatedHeaderProps {
  className?: string;
  currentPageNum?: number;
}

export const UnauthenticatedHeader = (props: UnauthenticatedHeaderProps) => {
  const { className = '', currentPageNum } = props;

  return (
    <header className={`unathenticated__header ${className}`}>
      <LpLink to={frontend.login.url({})}>
        <img className="unathenticated__logo" src={portfolioManagerNewLogo} alt="Portfolio Manager Logo" />
      </LpLink>
      {currentPageNum && <span className="header__pager">{currentPageNum}/2</span>}
    </header>
  );
};
