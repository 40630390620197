import { noop } from 'lodash';
import { ReactNode, createContext, useContext } from 'react';

interface SyncErrorModalContextProps {
  setEditModalOpen: (open: boolean) => void;
  setBulkEditModalOpen: (open: boolean) => void;
  packageId: number;
}

type SyncErrorModalProviderProps = SyncErrorModalContextProps & { children: ReactNode };

export const SyncErrorModalContext = createContext<SyncErrorModalContextProps>({
  setEditModalOpen: noop,
  setBulkEditModalOpen: noop,
  packageId: 0,
});

export const useSyncErrorModalContext = () => {
  const context = useContext(SyncErrorModalContext);

  return context;
};

export const SyncErrorModalProvider = ({
  children,
  setEditModalOpen,
  setBulkEditModalOpen,
  packageId,
}: SyncErrorModalProviderProps) => (
  <SyncErrorModalContext.Provider value={{ setEditModalOpen, packageId, setBulkEditModalOpen }}>
    {children}
  </SyncErrorModalContext.Provider>
);
