import LpLink from 'containers/shared/lp_link';
import { ContactSupportLink } from 'features/common/buttons/contact_support_link';
import { frontend } from 'lib/urls';
import { ProductName } from 'lib/use_product_name';

enum RegistrationErrorCodes {
  RecordNotFound = 'record_not_found',
  DuplicateValue = 'duplicate_value',
}

export const RegistrationErrors = ({ code, detail }: { code: string; detail?: string }) => {
  if (code === RegistrationErrorCodes.RecordNotFound && detail?.includes('signupKeys')) {
    return (
      <>
        Please enter the signup key provided to you by <ProductName /> staff members.
      </>
    );
  } else if (code === RegistrationErrorCodes.DuplicateValue && detail?.includes('email')) {
    return (
      <>
        This email address is already in our system.
        <span>
          Please <LpLink to={frontend.login.url({})}>{'Sign In'}</LpLink>.
        </span>
      </>
    );
  } else {
    return detail ? (
      <>{detail}</>
    ) : (
      <>
        <ContactSupportLink text="Contact Support" />.
      </>
    );
  }
};
