export const PublishedWithChangesSvg = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="icon">
        <path
          id="Vector"
          d="M9 17.9375C8.01389 17.8125 7.09375 17.5208 6.23958 17.0625C5.38542 16.6042 4.64583 16.0208 4.02083 15.3125C3.39583 14.6042 2.90278 13.7951 2.54167 12.8854C2.18056 11.9757 2 11.0139 2 10C2 8.79167 2.24306 7.67708 2.72917 6.65625C3.21528 5.63542 3.875 4.75 4.70833 4H3V2.5H7.5V7H6V4.875C5.23611 5.47222 4.62847 6.21528 4.17708 7.10417C3.72569 7.99306 3.5 8.95833 3.5 10C3.5 11.6389 4.02778 13.059 5.08333 14.2604C6.13889 15.4618 7.44444 16.1806 9 16.4167V17.9375ZM8.9375 13L6.10417 10.1667L7.16667 9.10417L8.9375 10.875L12.8333 7L13.8958 8.04167L8.9375 13ZM12.5 17.5V13H14V15.125C14.7639 14.5278 15.3715 13.7847 15.8229 12.8958C16.2743 12.0069 16.5 11.0417 16.5 10C16.5 8.36111 15.9722 6.94097 14.9167 5.73958C13.8611 4.53819 12.5556 3.81944 11 3.58333V2.0625C12.9861 2.3125 14.6493 3.18403 15.9896 4.67708C17.3299 6.17014 18 7.94444 18 10C18 11.2083 17.7569 12.3229 17.2708 13.3438C16.7847 14.3646 16.125 15.25 15.2917 16H17V17.5H12.5Z"
          fill="#1D1D1B"
        />
      </g>
    </svg>
  );
};
