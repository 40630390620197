/*
hoursChangeValidationRegEx explained:
starts and ends with a period |
starts with a number (any amount) and ends with a period |
starts with a number (any amount), with a period between, and ends with a number (any amount) |
starts with a period and ends with a number (any amount) |
starts and ends with a number (any amount) |
any of the previous rules are allowed ending in h,m, or d
*/

export const hoursInputValidationRegEx = new RegExp('^(\\.|[0-9]+\\.|[0-9]+\\.[0-9]+|\\.[0-9]+|[0-9]+)([hmd]?)$', 'i');

export const hoursWideRangeInputValidationRegEx = new RegExp(
  '^(\\.|[0-9]+\\.|[0-9]+\\.[0-9]+|\\.[0-9]+|[0-9]+)([mhdwqy]|mo)?$',
  'i'
);
