export enum SourceSystem {
  JIRA = 'jira',
  ROADMUNK = 'roadmunk',
}

export enum ConnectedStatus {
  CONNECTED = 'CONNECTED',
  DISCONNECTED = 'DISCONNECTED',
}

export enum ExternalIntegrationStatusValues {
  INITIALIZED = 'INITIALIZED',
  ERROR = 'ERROR',
  COMPLETE = 'COMPLETE',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETE_WITH_ERROR = 'COMPLETE_WITH_ERROR',
}

export enum SyncProjectJobErrorType {
  ProjectNotFound = 'PROJECT_NOT_FOUND',
  InvalidAuthentication = 'INVALID_AUTHENTICATION',
  OverallSync = 'OVERALL_SYNC',
  ResourceLimitExceeded = 'RESOURCE_LIMIT_EXCEEDED',
  JqlFilterParse = 'JQL_FILTER_PARSE',
  SetCustomFieldValue = 'SET_CUSTOM_FIELD_VALUE',
  ConfigureStatuses = 'CONFIGURE_STATUSES',
  CreateAssignment = 'CREATE_ASSIGNMENT',
  CreateTask = 'CREATE_TASK',
  ConfigureCustomField = 'CONFIGURE_CUSTOM_FIELD',
  ConfigureIssueType = 'CONFIGURE_ISSUE_TYPE',
  ConfigureIssuePriority = 'CONFIGURE_ISSUE_PRIORITY',
  ItemIssueSync = 'ITEM_ISSUE_SYNC',
  UpdateTask = 'UPDATE_TASK',
  UpdateJiraStatus = 'UPDATE_JIRA_STATUS',
  ProjectUserSync = 'PROJECT_USER_SYNC',
  UnlinkedItem = 'UNLINKED_ITEM',
  SubTaskIssueSync = 'SUBTASK_ISSUE_SYNC',
  ItemIssuePush = 'ITEM_ISSUE_PUSH',
  UpdateAssignment = 'UPDATE_ASSIGNMENT',
  DuplicateTask = 'DUPLICATE_TASK',
  PriorityNotFoundInJira = 'PRIORITY_NOT_FOUND_IN_JIRA',
  IssueRankPush = 'ISSUE_RANK_PUSH',
  FieldNotSettableInJira = 'FIELD_NOT_SETTABLE_IN_JIRA',
  WorklogSyncCostCodeError = 'WORKLOG_SYNC_COST_CODE_ERROR',
  WorklogSyncError = 'WORKLOG_SYNC_ERROR',
  AssigneeNotFoundInJira = 'ASSIGNEE_NOT_FOUND_IN_JIRA',
  ProjectLeadInactive = 'PROJECT_LEAD_INACTIVE',
  InvalidMappingFieldValue = 'INVALID_MAPPING_FIELD_VALUE',
  TargetStartAfterFinish = 'TARGET_START_AFTER_FINISH',
  StoryPointFieldNotFound = 'STORY_POINT_FIELD_NOT_FOUND',
  CreateIssuePermissionMissing = 'CREATE_ISSUE_PERMISSION_MISSING',
  UnknownPushError = 'UNKNOWN_PUSH_ERROR',
  MandatoryFieldValueUnset = 'MANDATORY_FIELD_VALUE_UNSET',
  MandatoryFieldUnmapped = 'MANDATORY_FIELD_UNMAPPED',
  IssueTypeDoesNotExist = 'ISSUE_TYPE_DOES_NOT_EXIST',
  IssueDependencyPush = 'ISSUE_DEPENDENCY_PUSH',
  IssueTypeUnset = 'ISSUE_TYPE_UNSET',
  SprintIterationSync = 'SPRINT_ITERATION_SYNC',
  IssueDescriptionPush = 'ISSUE_DESCRIPTION_PUSH',
  IssueSprintPull = 'ISSUE_SPRINT_PULL',
  PullChanges = 'PULL_CHANGES',
  IssuePushClosedSprint = 'ISSUE_PUSH_CLOSED_SPRINT',
}

export enum TaskGroupHierarchy {
  GroupFlatLevel = 'GROUP_FLAT_LEVEL',
  GroupByEpic = 'GROUP_BY_EPIC',
  GroupByParentAncestry = 'GROUP_BY_PARENT_ANCESTRY',
}

export enum EstimationMappingType {
  StoryPoints = 'storyPoints',
  RemainingTime = 'remainingTime',
}

export enum EstimationType {
  StoryPoints = 'STORY_POINTS',
  RemainingTime = 'REMAINING_ESTIMATE',
}

export enum StoryPointSchemeActions {
  CreateNew = 'CREATE_NEW',
  UseExisting = 'USE_EXISTING',
  UseDefault = 'USE_DEFAULT',
}

export enum TaskPushSetting {
  Manual = 'MANUAL',
  Off = 'OFF',
}
