import { Button, Form } from 'semantic-ui-react';

import { RegistrationErrors } from 'features/authentication/unauthenticated/registration_v2/errors';
import LpErrorMessage from 'features/common/errors/lp_error_message';
import { LpFormDropdown } from 'features/common/forms/lp_form_dropdown';
import LpFormInput from 'features/common/forms/lp_form_input';

enum CompanySize {
  from0To49 = 'from0To49',
  from50To99 = 'from50To99',
  from100To249 = 'from100To249',
  from250To499 = 'from250To499',
  from500To1999 = 'from500To1999',
  from2000To4999 = 'from2000To4999',
  from5000 = 'from5000',
}

const companySizes = [
  CompanySize.from0To49,
  CompanySize.from50To99,
  CompanySize.from100To249,
  CompanySize.from250To499,
  CompanySize.from500To1999,
  CompanySize.from2000To4999,
  CompanySize.from5000,
];

const companySizeDisplayValue = {
  [CompanySize.from0To49]: '0-49',
  [CompanySize.from50To99]: '50-99',
  [CompanySize.from100To249]: '100-249',
  [CompanySize.from250To499]: '250-499',
  [CompanySize.from500To1999]: '500-1,999',
  [CompanySize.from2000To4999]: '2,000-4,999',
  [CompanySize.from5000]: '5,000+',
};

enum JobTitle {
  businessOwner = 'businessOwner',
  executive = 'executive',
  teamManager = 'teamManager',
  individualContributor = 'individualContributor',
  consultantPartner = 'consultantPartner',
  student = 'student',
  unknown = 'unknown',
}

const jobTitles = [
  JobTitle.businessOwner,
  JobTitle.executive,
  JobTitle.teamManager,
  JobTitle.individualContributor,
  JobTitle.consultantPartner,
  JobTitle.student,
  JobTitle.unknown,
];

const jobTitleDisplayValue = {
  [JobTitle.businessOwner]: 'Business Owner',
  [JobTitle.executive]: 'Executive',
  [JobTitle.teamManager]: 'Team Manager',
  [JobTitle.individualContributor]: 'Individual Contributor',
  [JobTitle.consultantPartner]: 'Consultant/Partner',
  [JobTitle.student]: 'Student',
  [JobTitle.unknown]: 'Rather Not Say',
};

const companySizeOptions = companySizes.map((companySize) => ({
  key: companySize.toString(),
  search: companySizeDisplayValue[companySize],
  text: companySizeDisplayValue[companySize],
  value: companySize.toString(),
}));

const jobTitleOptions = jobTitles.map((jobTitle) => ({
  key: jobTitle.toString(),
  search: jobTitleDisplayValue[jobTitle],
  text: jobTitleDisplayValue[jobTitle],
  value: jobTitle.toString(),
}));

const SIGNUP_KEY_LABEL = 'Signup Key';
const requireSignupKey = window.relightRequireSignupKey === 'true';

export default function Step1(props: any) {
  const {
    handleSubmit,
    isSubmitting,
    status: formApiError,
    handleDismissApiError,
    getFieldProps,
    getFieldMeta,
    setFieldValue,
  } = props;

  return (
    <Form className="steps__form" autoComplete="on" onSubmit={handleSubmit} loading={isSubmitting}>
      {formApiError && (
        <LpErrorMessage
          className="form__api-error"
          error={formApiError}
          customError={<RegistrationErrors code={formApiError.code} detail={formApiError.detail} />}
          onDismiss={handleDismissApiError}
        />
      )}
      <div className="form__column">
        <LpFormInput
          e2eTestId="firstName"
          fieldKey="firstName"
          className="form__input-field"
          label="First Name*"
          getFieldProps={getFieldProps}
          getFieldMeta={getFieldMeta}
          placeholder="Enter your first name"
        />

        <LpFormInput
          e2eTestId="lastName"
          fieldKey="lastName"
          className="form__input-field"
          label="Last Name*"
          getFieldProps={getFieldProps}
          getFieldMeta={getFieldMeta}
          placeholder="Enter your last name"
        />
      </div>

      <LpFormInput
        e2eTestId="companyName"
        fieldKey="companyName"
        className="form__input-field"
        getFieldProps={getFieldProps}
        getFieldMeta={getFieldMeta}
        label="Company Name*"
        placeholder="Enter company name"
      />

      <LpFormInput
        e2eTestId="phoneNumber"
        fieldKey="phoneNumber"
        className="form__input-field"
        label="Business Phone Number*"
        type="tel"
        getFieldProps={getFieldProps}
        getFieldMeta={getFieldMeta}
        placeholder="Enter business phone number"
      />

      <LpFormDropdown
        e2eTestId="jobTitle"
        fieldKey="jobTitle"
        className="form__dropdown-field"
        getFieldProps={getFieldProps}
        getFieldMeta={getFieldMeta}
        label="Role*"
        setFieldValue={setFieldValue}
        options={jobTitleOptions}
        placeholder="Select your role"
      />

      <LpFormDropdown
        e2eTestId="companySize"
        fieldKey="companySize"
        className="form__dropdown-field"
        label="Company Size*"
        getFieldMeta={getFieldMeta}
        getFieldProps={getFieldProps}
        setFieldValue={setFieldValue}
        options={companySizeOptions}
        placeholder="Choose company size"
      />

      {requireSignupKey && (
        <LpFormInput
          fieldKey="signupKey"
          className="form__input-field"
          label={SIGNUP_KEY_LABEL}
          getFieldProps={getFieldProps}
          getFieldMeta={getFieldMeta}
        />
      )}
      <Button
        data-e2e-test-id="createAccount"
        className="form__button"
        content="Create Account →"
        type="submit"
        disabled={isSubmitting}
      />
    </Form>
  );
}
