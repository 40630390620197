import moment from 'moment-timezone';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
  convertDatePartsToMomentObject,
  convertDateStringToMomentObject,
  convertLocalDateToSpecifiedTimezoneAndFormat,
  currentBrowserDateTime as localizedCurrentBrowserDateTime,
  currentBrowserTimezone,
  currentIsoDateTime as localizedCurrentIsoDateTime,
  dateDifference as localizedDateDifference,
  DateParts,
  DEFAULT_DATE_FORMAT,
  DEFAULT_FIRST_DAY_OF_WEEK,
  DEFAULT_TIME_FORMAT,
  formatCurrency as localizedFormatCurrency,
  formatLocalDate as localizedFormatLocalDate,
  formatLocalDateWithTime as localizedFormatLocalDateWithTime,
  formatLocalDayOfWeekAndDate as localizedFormatLocalDayOfWeekAndDate,
  formatLocaleDateWithTimeForChanges as localizedLocaleDateWithTimeForChanges,
  formatLocalTimeFromDate as localizedFormatLocalTimeFromDate,
  formatNumber as localizedFormatNumber,
  formatWeekdayShort as localizedFormatWeekdayShort,
  localCalendarDayDurationDays as localizedLocalCalendarDayDurationDays,
  localWeekdayNumbers as localizedLocalWeekdayNumbers,
  parseLocalDateWithWorkspaceTimeAsIsoDateTime as localizedParseLocalDateWithWorkspaceTimeAsIsoDateTime,
  rawStringToIsoDate as localizedRawStringToIsoDate,
  stringDateDifference as localizedStringDateDifference,
  stringTimestampDifference as localizedStringTimestampDifference,
} from 'lib/localization';
import { NumberFormat } from 'lib/localization_enums';
import { WorkdayTimeType } from 'lib/workday_time_type';
import { getCurrentOrganizationUser } from 'redux/entities/selectors/user';
import { getCurrentWorkspace } from 'redux/entities/selectors/workspace';

export const useLocalizedFormats = () => {
  const organizationUser = useSelector(getCurrentOrganizationUser);
  const workspace = useSelector(getCurrentWorkspace);
  const dateFormat = organizationUser?.dateFormat ?? DEFAULT_DATE_FORMAT;
  const timeFormat = organizationUser?.timeFormat ?? DEFAULT_TIME_FORMAT;
  const timezone = organizationUser?.timezone ?? currentBrowserTimezone();
  const firstDayOfWeek = organizationUser?.firstDayOfWeek ?? DEFAULT_FIRST_DAY_OF_WEEK;

  return useMemo(
    () => ({
      convertDatePartsToMomentTZ: (dateParts: DateParts) => convertDatePartsToMomentObject(dateParts, timezone),
      convertLocalDateToSpecifiedTimezoneAndFormat: (date: string, format: string) =>
        convertLocalDateToSpecifiedTimezoneAndFormat(date, timezone, format),
      currentBrowserDateTime: () => localizedCurrentBrowserDateTime(timezone),
      currentIsoDateTime: () => localizedCurrentIsoDateTime(timezone),
      dateDifference: (date1: moment.Moment, date2: moment.Moment, format?: moment.unitOfTime.Diff) =>
        localizedDateDifference(date1, date2, format),
      formatCurrency: (value: number | string, currencyUnit: string, format: NumberFormat) =>
        localizedFormatCurrency(value, currencyUnit, format),
      formatLocalDate: (dateString: string) => localizedFormatLocalDate(dateFormat, dateString, timezone),
      formatLocalDateWithTime: (dateString: string) =>
        localizedFormatLocalDateWithTime(dateFormat, timeFormat, dateString, timezone),
      formatLocalDayOfWeekAndDate: (dateString: string) =>
        localizedFormatLocalDayOfWeekAndDate(dateFormat, timezone, dateString),
      formatLocaleDateWithTimeForChanges: (dateString: string) =>
        localizedLocaleDateWithTimeForChanges(dateFormat, timeFormat, timezone, dateString),
      formatLocalTimeFromDate: (dateTimeString: string) =>
        localizedFormatLocalTimeFromDate(timeFormat, dateTimeString, timezone),
      formatNumber: (value: string | number, format: NumberFormat) => localizedFormatNumber(value, format),
      formatWeekdayShort: (number: number) => localizedFormatWeekdayShort(number),
      localCalendarDayDurationDays: (start: moment.Moment, finish: moment.Moment) =>
        localizedLocalCalendarDayDurationDays(start, finish, timezone),
      localWeekdayNumbers: () => localizedLocalWeekdayNumbers(firstDayOfWeek),
      parseLocalDateWithWorkspaceDateFormatAsMoment: (dateString: string) =>
        convertDateStringToMomentObject(dateString, dateFormat),
      parseLocalDateWithWorkspaceTimeAsIsoDateTime: (dateString: string, workdayTime: WorkdayTimeType) =>
        localizedParseLocalDateWithWorkspaceTimeAsIsoDateTime(dateFormat, workspace, dateString, workdayTime, timezone),
      rawStringToIsoDate: (value: string) => localizedRawStringToIsoDate(value),
      stringDateDifference: (date1: string, date2: string, unitOfTime?: moment.unitOfTime.Diff) =>
        localizedStringDateDifference(dateFormat, date1, date2, unitOfTime),
      stringTimestampDifference: (timestamp1: string, timestamp2: string, unitOfTime?: moment.unitOfTime.Diff) =>
        localizedStringTimestampDifference(timezone, timestamp1, timestamp2, unitOfTime),
    }),
    [dateFormat, firstDayOfWeek, timeFormat, timezone, workspace]
  );
};
