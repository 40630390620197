import { ReactNode, useEffect } from 'react';

import unauthAside from 'static/img/unauth_aside_bg.png';

import './layout_v2.scss';

const useMobileDeviceViewportWidth = () => {
  useEffect(() => {
    const viewportElement = document.getElementById('viewport');

    viewportElement?.setAttribute('content', 'width=device-width, initial-scale=1.0');

    return () => {
      viewportElement?.setAttribute('content', 'width=1024, initial-scale=1.0');
    };
  }, []);
};

export const UnauthenticatedLayoutV2 = ({ children }: { children: ReactNode }) => {
  useMobileDeviceViewportWidth();

  return (
    <div className="pm-unauthenticated">
      <div className="pm-unauthenticated__container">
        <div className="pm-unauthenticated__content">{children}</div>
        <div className="pm-unauthenticated__aside">
          <img src={unauthAside} alt="" srcSet="" />
        </div>
      </div>
    </div>
  );
};
