import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { List, Table } from 'semantic-ui-react';

import { PlanFamily, BillingPeriod, planFamilyDisplayText, BillingPeriodDisplay } from 'daos/enums';
import { OrganizationDao } from 'daos/organization';
import { PlanDao } from 'daos/plan';
import { CallToActionLink } from 'features/common/calls_to_action/link';
import { getCurrentOrganizationId } from 'features/common/current/selectors';
import {
  arrowAltSquareDownSolid,
  arrowAltSquareUpSolid,
  checkSolid,
  folderClosedSolid,
  IconDefinition,
  LpIcon,
  plusSolid,
  plusSquareSolid,
  dotCircleSolid,
  fileSolid,
} from 'features/common/lp_icon';
import { useLocalizedFormats } from 'hooks/use_locale_from_user';
import { HORIZONTAL_ELLIPSIS } from 'lib/constants';
import roundToTwoDecimalPlaces from 'lib/display_helpers/round_to_two_decimal_places';
import NewWindowLink from 'lib/display_helpers/url_links';
import { thirdPartyUrls, frontend } from 'lib/urls';
import { getCurrentOrganization } from 'redux/entities/selectors/organization';
import { getActivePlans, getCurrentOrganizationPlan } from 'redux/entities/selectors/plan';
import { getRateData } from 'redux/entities/selectors/rate_data';
import { getCurrentOrganizationUser } from 'redux/entities/selectors/user';

import { findPlan } from './plan_change';

const NotAvailable = () => <span className="manage-account__rates-details-not-available">n/a</span>;

const rateTablePlanFamilies: ReadonlyArray<PlanFamily> = [
  PlanFamily.Essentials,
  PlanFamily.Professional,
  PlanFamily.Ultimate,
];

const Savings = ({ savings }: { savings?: number | null }) => (
  <span className="manage-account__rates-details-savings">
    {savings ? <>Save {Math.round(100 * savings)}%</> : <>&nbsp;</>}
  </span>
);

const Price = ({ price }: { price?: number | null }) => {
  const { formatNumber } = useLocalizedFormats();
  const currentOrganizationUser = useSelector(getCurrentOrganizationUser);

  if (!currentOrganizationUser) {
    return <NotAvailable />;
  }

  return (
    <span className="manage-account__rates-details-price">
      ${price && formatNumber(roundToTwoDecimalPlaces(price / 100), currentOrganizationUser.numberFormat)}
    </span>
  );
};

const ListWithIcon = ({ items, icon }: { items: Array<String>; icon: IconDefinition }) => (
  <List>
    {items.map((item, idx) => (
      <List.Item key={idx}>
        <LpIcon icon={icon} />
        {item}
      </List.Item>
    ))}
  </List>
);

export const ManageAccountRates = () => {
  const dispatch = useDispatch();

  const currentOrganizationId = useSelector(getCurrentOrganizationId);

  useEffect(() => {
    dispatch(OrganizationDao.fetchRateData({ organizationId: currentOrganizationId }));
    dispatch(PlanDao.fetchAll({ organizationId: currentOrganizationId }));
  }, [dispatch, currentOrganizationId]);

  return (
    <div className="manage-account__rates">
      <div className="manage-account__rates-header">
        <p className="manage-account__rates-header-label">
          Compare Plans<sup>*</sup>
        </p>
        <RatesHeaderButtons />
      </div>
      <Table className="manage-account__rates-details">
        <Table.Header>
          <Table.Row>
            {rateTablePlanFamilies.map((family) => (
              <Table.HeaderCell key={family}>{planFamilyDisplayText[family]}</Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <PriceRatesRow />
          <BuyNowRateTableRow />
          <LimitsAndFeatureRows />
        </Table.Body>
      </Table>
      <RatesFooter />
    </div>
  );
};

const RatesHeaderButtons = () => {
  const currentOrganization = useSelector(getCurrentOrganization);
  const currentPlan = useSelector(getCurrentOrganizationPlan);
  if (
    !currentOrganization ||
    !currentOrganization.flags.allowSelfService ||
    !currentPlan ||
    currentPlan.billingPeriod === BillingPeriod.Other
  ) {
    return null;
  }

  const canAddLicenses = currentPlan.upgradeFamilies.length > 0;
  const canUpgrade = canAddLicenses && currentPlan.upgradeFamilies.some((family) => family !== currentPlan.family);
  const canDowngrade = currentPlan.downgradeFamilies.length > 0;

  return (
    <div className="manage-account__rates-header-btns">
      {canAddLicenses && (
        <CallToActionLink
          className="manage-account__rates-upgrade-btn"
          to={frontend.organizationHubManageAccountPlanUpgrade.url({
            organizationId: currentOrganization.id,
          })}
        >
          <LpIcon icon={plusSquareSolid} />
          Add Licenses
        </CallToActionLink>
      )}

      {canUpgrade && (
        <CallToActionLink
          className="manage-account__rates-upgrade-btn"
          to={frontend.organizationHubManageAccountPlanUpgrade.url({
            organizationId: currentOrganization.id,
          })}
        >
          <LpIcon icon={arrowAltSquareUpSolid} />
          Upgrade Plan
        </CallToActionLink>
      )}

      {canDowngrade && (
        <CallToActionLink
          className="manage-account__rates-downgrade-btn"
          to={frontend.organizationHubManageAccountPlanDowngrade.url({
            organizationId: currentOrganization.id,
          })}
        >
          <LpIcon icon={arrowAltSquareDownSolid} />
          Downgrade
        </CallToActionLink>
      )}
    </div>
  );
};

const PriceRatesRow = () => {
  const rateData = useSelector(getRateData);
  const currentPlan = useSelector(getCurrentOrganizationPlan);

  const billingPeriod =
    !currentPlan || currentPlan.billingPeriod === BillingPeriod.Other
      ? BillingPeriod.Annual
      : currentPlan.billingPeriod;

  const rateColumnForFamily = (family: PlanFamily) => {
    const rateDataForBillingPeriod =
      family === currentPlan?.family ? rateData?.[billingPeriod] : rateData?.[BillingPeriod.Annual];

    if (family === PlanFamily.Essentials) {
      return rateDataForBillingPeriod?.essentials;
    } else if (family === PlanFamily.Professional) {
      return rateDataForBillingPeriod?.professional;
    } else if (family === PlanFamily.Ultimate) {
      return rateDataForBillingPeriod?.ultimate;
    }
  };

  return (
    <Table.Row>
      {rateTablePlanFamilies.map((family) => {
        const rateColumn = rateColumnForFamily(family);

        return (
          <Table.Cell key={family}>
            <p className="lighter">
              Per license per month, billed{' '}
              {BillingPeriodDisplay[family === currentPlan?.family ? billingPeriod : BillingPeriod.Annual]}
            </p>
            {rateColumn ? (
              <>
                <Price price={rateColumn.monthlyLicenseCents} />
                {family === PlanFamily.Ultimate && <> (before volume discount)</>}
                <Savings savings={rateColumn.licenseSavings} />
              </>
            ) : (
              <NotAvailable />
            )}
          </Table.Cell>
        );
      })}
    </Table.Row>
  );
};

const BuyNowRateTableRow = () => {
  const organization = useSelector(getCurrentOrganization);
  const currentPlan = useSelector(getCurrentOrganizationPlan);
  const activePlans = useSelector(getActivePlans);

  if (
    !organization ||
    !currentPlan ||
    currentPlan.billingPeriod !== BillingPeriod.Other ||
    !organization.flags.allowSelfService
  ) {
    return null;
  }

  return (
    <Table.Row>
      {rateTablePlanFamilies.map((family) => (
        <Table.Cell key={family}>
          <CallToActionLink
            className="manage-account__rates-upgrade-btn"
            to={`${frontend.organizationHubManageAccountPlanUpgrade.url({
              organizationId: organization.id,
            })}?planId=${findPlan({ activePlans, family, billingPeriod: BillingPeriod.Annual })?.id}`}
          >
            <LpIcon icon={arrowAltSquareUpSolid} />
            Buy Now
          </CallToActionLink>
          <br />
          <p>
            <br />
            <NewWindowLink to={thirdPartyUrls.productAdvisor}>Contact Sales</NewWindowLink>
            <br />
            <span className="lighter ">{family === PlanFamily.Ultimate ? <>For volume pricing</> : <>&nbsp;</>}</span>
          </p>
        </Table.Cell>
      ))}
    </Table.Row>
  );
};

const LimitsAndFeatureRows = () => {
  const { formatNumber } = useLocalizedFormats();
  const currentOrganizationUser = useSelector(getCurrentOrganizationUser);

  const rateData = useSelector(getRateData);
  const currentPlan = useSelector(getCurrentOrganizationPlan);

  if (!currentOrganizationUser) {
    return null;
  }

  const billingPeriod =
    !currentPlan || currentPlan.billingPeriod === BillingPeriod.Other
      ? BillingPeriod.Annual
      : currentPlan.billingPeriod;

  const rateDataForFamily = (family: PlanFamily) =>
    family === currentPlan?.family ? rateData?.[billingPeriod] : rateData?.[BillingPeriod.Annual];

  const limitsAndFeaturesData = {
    essentials: {
      taskLimit: rateDataForFamily(PlanFamily.Essentials)?.essentials?.taskLimit,
      projectLimit: rateDataForFamily(PlanFamily.Essentials)?.essentials?.projectLimit,
      fileStorageLimitInGB: rateDataForFamily(PlanFamily.Essentials)?.essentials?.fileStorageLimitInGB,
    },
    professional: {
      taskLimit: rateDataForFamily(PlanFamily.Professional)?.professional?.taskLimit,
      projectLimit: rateDataForFamily(PlanFamily.Professional)?.professional?.projectLimit,
      fileStorageLimitInGB: rateDataForFamily(PlanFamily.Professional)?.professional?.fileStorageLimitInGB,
    },
    ultimate: {
      fileStorageLimitInGB: rateDataForFamily(PlanFamily.Ultimate)?.ultimate?.fileStorageLimitInGB,
    },
  };

  const rateLimit = (limit?: number | null) =>
    limit ? <>{formatNumber(limit, currentOrganizationUser.numberFormat)}</> : <NotAvailable />;

  return (
    <>
      <Table.Row className="manage-account__rates-details-features manage-account__rates-details-counts">
        <Table.Cell>
          <LpIcon icon={dotCircleSolid} /> {rateLimit(limitsAndFeaturesData.essentials.taskLimit)} Tasks
        </Table.Cell>
        <Table.Cell>
          <LpIcon icon={dotCircleSolid} /> {rateLimit(limitsAndFeaturesData.professional.taskLimit)} Tasks
        </Table.Cell>
        <Table.Cell>
          <LpIcon icon={dotCircleSolid} /> Unlimited Tasks
        </Table.Cell>
      </Table.Row>
      <Table.Row className="manage-account__rates-details-features manage-account__rates-details-counts">
        <Table.Cell>
          <LpIcon icon={folderClosedSolid} /> {rateLimit(limitsAndFeaturesData.essentials.projectLimit)} Projects
        </Table.Cell>
        <Table.Cell>
          <LpIcon icon={folderClosedSolid} /> {rateLimit(limitsAndFeaturesData.professional.projectLimit)} Projects
        </Table.Cell>
        <Table.Cell>
          <LpIcon icon={folderClosedSolid} /> Unlimited Projects
        </Table.Cell>
      </Table.Row>
      <Table.Row className="manage-account__rates-details-features manage-account__rates-details-counts">
        <Table.Cell>
          <LpIcon icon={fileSolid} /> {rateLimit(limitsAndFeaturesData.essentials.fileStorageLimitInGB)} GB File Storage
        </Table.Cell>
        <Table.Cell>
          <LpIcon icon={fileSolid} /> {rateLimit(limitsAndFeaturesData.professional.fileStorageLimitInGB)} GB File
          Storage
        </Table.Cell>
        <Table.Cell>
          <LpIcon icon={fileSolid} /> {rateLimit(limitsAndFeaturesData.ultimate.fileStorageLimitInGB)} GB File Storage
        </Table.Cell>
      </Table.Row>
      <Table.Row className="manage-account__rates-details-features">
        <Table.Cell>
          <p>
            <em>Included with all plans</em>
          </p>
          <ListWithIcon
            icon={checkSolid}
            items={[
              'Predictive Scheduling',
              'Task Management',
              'Integrated Time-Tracking',
              'Portfolio Views',
              'Project Views',
              'Board Views',
              'Workload Views',
              'Grid Views',
              'Timesheet Views',
              'Personal Work Views',
              'Change Tracking',
              'Availability Management',
              'Alerts & Insights',
              'Customizable Dashboards',
            ]}
          />
        </Table.Cell>
        <Table.Cell>
          <p>
            <em>Everything in ESSENTIALS plus{HORIZONTAL_ELLIPSIS}</em>
          </p>
          <ListWithIcon
            icon={plusSolid}
            items={[
              'Custom Data Fields',
              'Custom Filtering',
              'Customizable Cost Codes',
              'Timesheet Review',
              'Timesheet Export',
            ]}
          />
        </Table.Cell>

        <Table.Cell>
          <p>
            <em>Everything in PROFESSIONAL plus{HORIZONTAL_ELLIPSIS}</em>
          </p>
          <ListWithIcon
            icon={plusSolid}
            items={[
              'Enterprise Pricing & Support',
              'Multiple Workspaces',
              'Member & Resource Groups',
              'Group Workload Views',
              'Group Board Views',
              'Access Roles',
              'Project Access Controls',
            ]}
          />
        </Table.Cell>
      </Table.Row>
    </>
  );
};

const RatesFooter = () => (
  <div className="manage-account__rates-footer">
    <p>
      <sup>*</sup> Pricing Notes
    </p>
    <ul>
      <li>
        The <strong>FREE PLAN</strong> is a limited version of <strong>ESSENTIALS</strong>
      </li>
      <li>Each user license grants two resources for modeling non-members and machines</li>
      <li>Savings relative to a monthly billing cycle</li>
      <li>Applicable taxes not included</li>
    </ul>
  </div>
);
