import classNames from 'classnames';
import { Image } from 'semantic-ui-react';

import NewWindowLink from 'lib/display_helpers/url_links';
import { thirdPartyUrls } from 'lib/urls';
import pmLogo from 'static/img/portfolio-manager-logo.svg';

export const LpLogo = ({ className }: { className?: string }) => (
  <NewWindowLink to={thirdPartyUrls.portfolioManagerMktg}>
    <Image src={pmLogo} className={classNames('lp-logo', 'clickable', className)} />
  </NewWindowLink>
);
