import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Form } from 'semantic-ui-react';
import * as yup from 'yup';

import LpLink from 'containers/shared/lp_link';
import { User } from 'daos/model_types';
import { UserDao } from 'daos/user';
import { useHandleDisconnected } from 'features/authentication/helpers';
import { FrontloadDataProps } from 'features/authentication/hooks/use_frontload_data';
import { LoginError } from 'features/authentication/unauthenticated/login_v2/login_errors';
import { setCurrentUserId } from 'features/common/current/slice';
import LpErrorMessage from 'features/common/errors/lp_error_message';
import LpFormCheckbox from 'features/common/forms/lp_form_checkbox';
import LpFormInput from 'features/common/forms/lp_form_input';
import { clearApiError } from 'features/errors/slice';
import useQueryParams from 'hooks/use_query_params';
import { awaitRequestFinish } from 'lib/api';
import { lpErrorText } from 'lib/helpers/yup/lp_error_text';
import { frontend } from 'lib/urls';
import { ProductName } from 'lib/use_product_name';
import { resetRootState } from 'redux/root_actions';

const schema = yup.object().shape({
  email: yup.string().trim().email(lpErrorText.email).required(lpErrorText.email),
  password: yup.string().required('Password required.'),
  rememberMe: yup.boolean(),
});

export const LpLoginForm = ({
  redirectLocation,
  frontloadData,
}: {
  redirectLocation: string | undefined;
  frontloadData: ({ organizations }: FrontloadDataProps) => void;
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const newEmail = useQueryParams().newEmail;

  const handleDisconnected = useHandleDisconnected();

  const {
    handleSubmit,
    isSubmitting,
    setSubmitting,
    getFieldMeta,
    getFieldProps,
    setStatus: setFormAPIError,
    status: formAPIError,
    errors,
    setFieldError,
  } = useFormik({
    initialValues: {
      email: '',
      password: '',
      rememberMe: true,
    },
    validationSchema: schema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: ({ email, password, rememberMe }) => {
      setFormAPIError(undefined);
      const { uuid } = dispatch(UserDao.login({ email, password, rememberMe }));
      dispatch(
        awaitRequestFinish<User>(uuid, {
          onError: ({ errors }) => {
            if (errors[0]) {
              setFormAPIError(errors[0]);
            }
            setSubmitting(false);
          },
          onSuccess: ({ data, entities }) => {
            dispatch(clearApiError());
            setSubmitting(false);
            const user = entities?.users ? entities.users[data.id] : null;

            if (user?.lockedAccount) {
              dispatch(resetRootState({ setCurrentUserIdNull: true }));
              return history.push(frontend.emailConfirmationExpired.url({}));
            }

            dispatch(setCurrentUserId(data.id));

            if (!entities.organizations) {
              return handleDisconnected();
            }

            frontloadData({
              organizations: entities.organizations,
              pathname: `${redirectLocation}`,
            });
            history.push(redirectLocation ?? '/');
          },
        })
      );
    },
  });

  const handleDismissApiError = () => setFormAPIError(undefined);

  const { password: passwordError, email: emailError } = errors;
  const clearEmailError = () => !!emailError && setFieldError('email', undefined);
  const clearPasswordError = () => !!passwordError && setFieldError('password', undefined);
  const emailLabel = newEmail ? `Business Email (Please enter your new address)` : 'Business Email*';

  return (
    <>
      {formAPIError && (
        <LpErrorMessage
          className="login__api-error"
          error={formAPIError}
          customError={<LoginError field={formAPIError.code} />}
          onDismiss={handleDismissApiError}
        />
      )}

      <Form className="login__form-lp" onSubmit={handleSubmit} loading={isSubmitting}>
        <LpFormInput
          className="form__input-field"
          disableLastPass={false}
          label={emailLabel}
          fieldKey="email"
          onFocus={clearEmailError}
          getFieldMeta={getFieldMeta}
          getFieldProps={getFieldProps}
          e2eTestId="login-email"
          placeholder="Example@business.com"
        />

        <LpFormInput
          className="form__input-field"
          disableLastPass={false}
          label="Password*"
          fieldKey="password"
          type="password"
          onFocus={clearPasswordError}
          getFieldMeta={getFieldMeta}
          getFieldProps={getFieldProps}
          e2eTestId="login-password"
          placeholder="Enter your password"
        />
        <div className="form__forgot-password">
          <LpLink className="form__forgot-password-link" to={frontend.forgotPassword.url({})}>
            Forgot Password?
          </LpLink>
        </div>

        <Form.Field>
          <LpFormCheckbox
            className="form__checkbox"
            fieldKey="rememberMe"
            getFieldMeta={getFieldMeta}
            getFieldProps={getFieldProps}
            label="Keep me signed in"
          />
        </Form.Field>

        <div className="form__actions">
          <Button
            className="form__button form__button--primary"
            data-e2e-test-id="login-submit"
            type="submit"
            content={'Sign In →'}
            disabled={isSubmitting}
          />
          <span>Or</span>
          <LpLink to={frontend.loginSso.url({})} className="form__button form__button--secondary">
            Sign In With SSO
          </LpLink>
        </div>
        <div className="form__signup-cta">
          New to <ProductName />
          ?&nbsp;
          <LpLink data-e2e-test-id="sign-up" to={frontend.trial.url({})}>
            Sign Up
          </LpLink>
        </div>
      </Form>
    </>
  );
};
