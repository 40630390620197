import { Dispatch, SetStateAction } from 'react';
import { useSelector } from 'react-redux';
import { Switch } from 'react-router';
import { Redirect, Route } from 'react-router-dom';

import { EmailConfirmationErrorV2 } from 'features/authentication/authenticated/email_confirmation_error_v2';
import { EntityTypeNotFound } from 'features/authentication/authenticated/errors/entity_not_found';
import { useFrontloadData } from 'features/authentication/hooks/use_frontload_data';
import { UnauthenticatedLayoutV2 } from 'features/authentication/unauthenticated/layout_v2';
import { getCurrentUserDisconnected, getCurrentUserId } from 'features/common/current/selectors';
import useMarketingTags from 'features/common/marketing_tags/use_marketing_tags';
import { useTrackPageViewedOnSegment } from 'features/common/segment/use_report_page_viewed_to_segment';
import { frontend } from 'lib/urls';

import { ForgotPasswordV2 } from './forgot_password_v2';
import { LoginPageV2 } from './login_v2';
import { SsoLoginCheckPageV2 } from './login_v2/login_sso';
import { RegistrationV2 } from './registration_v2';

import 'features/authentication/unauthenticated/index.scss';

type ReactSetStateAction = Dispatch<SetStateAction<number | undefined>>;

export const UnauthenticatedRoutes = ({
  setSelectedOrgId,
  setSelectedWsId,
  setEntityNotFound,
}: {
  setSelectedOrgId: ReactSetStateAction;
  setSelectedWsId: ReactSetStateAction;
  setEntityNotFound: Dispatch<SetStateAction<EntityTypeNotFound | undefined>>;
}) => {
  const currentUserId = useSelector(getCurrentUserId);
  const disconnected = useSelector(getCurrentUserDisconnected);
  const frontloadData = useFrontloadData({ setSelectedOrgId, setSelectedWsId, setEntityNotFound });

  useMarketingTags();
  useTrackPageViewedOnSegment();

  return (
    <UnauthenticatedLayoutV2>
      <Switch>
        <Route exact path={frontend.emailConfirmationExpired.pattern} render={() => <EmailConfirmationErrorV2 />} />

        {currentUserId && !disconnected && <Redirect to={frontend.authenticated.url({})} />}

        <Route exact path={frontend.trial.pattern} render={() => <RegistrationV2 frontloadData={frontloadData} />} />
        <Route exact path={frontend.login.pattern} render={() => <LoginPageV2 frontloadData={frontloadData} />} />
        <Route exact path={frontend.loginSso.pattern} render={() => <SsoLoginCheckPageV2 />} />
        <Route exact path={frontend.forgotPassword.pattern} render={() => <ForgotPasswordV2 />} />
      </Switch>
    </UnauthenticatedLayoutV2>
  );
};
