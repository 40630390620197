export enum NavigationMenuDisplay {
  AcademyHome = 'Academy',
  Access = 'Access',
  Active = 'Active',
  AddMember = 'Add Member',
  Administration = 'Administration',
  AdministrativeGroups = 'Administrative Groups',
  Admins = 'Admins',
  ALL = 'ALL',
  Allocation = 'Allocation',
  Api = 'API Access',
  ArchivedCollection = 'Archived',
  Assignments = 'Assignments',
  Availability = 'Availability',
  CapacityCollection = 'Capacity',
  Changes = 'Changes',
  Collections = 'Collections',
  Configuration = 'Configuration',
  Connections = 'Connections',
  ContactUs = 'Contact Us',
  CostCodes = 'Cost Codes',
  Create = 'Create',
  CustomData = 'Custom Data',
  CustomizeStatus = 'Customize Status',
  DashboardGuests = 'Dashboard Guests',
  Disconnected = 'Disconnected',
  ExportData = 'Export Data',
  ExportTimesheets = 'Export Timesheets',
  Favorites = 'My Favorites',
  Grid = 'Grid',
  Groups = 'Groups',
  GuestAccess = 'Guest Access',
  Invite = 'Invite',
  Iterations = 'Iterations',
  JiraConnections = 'Jira Connections',
  JiraIntegration = 'Jira Integration',
  Layout = 'Layout',
  Library = 'Library',
  LicensedUsers = 'Licensed Users',
  ManageAccount = 'Manage Account',
  ManageSso = 'Manage SSO',
  Member = 'Member',
  Members = 'Members',
  MembersAvailability = 'Members Availability',
  MyWork = 'My Work',
  OrgAdmins = 'Org Admins',
  OrganizationUsers = 'All Users',
  OrganizationUsersBreadcrumb = 'All Users',
  Package = 'Package',
  Packages = 'Packages',
  PendingCollection = 'Pending',
  PendingInvites = 'Pending Invites',
  PendingUsers = 'Pending Users',
  People = 'People',
  Placeholder = 'Placeholder',
  Portfolio = 'Portfolio',
  PortfolioAll = 'ALL Projects',
  PortfolioHome = 'Projects',
  PremiumFeaturesTrial = 'Premium Features Trial',
  PremiumTrial = 'Premium Trial',
  Profile = 'Profile',
  Profiles = 'Profiles',
  ProjectAccess = 'Project Access',
  ProjectCollections = 'Project Collections',
  ProjectHubTasklist = 'Project',
  Projects = 'Projects',
  RateSheets = 'Rate Sheets',
  ReportDesign = 'Report Design',
  Reports = 'Reports',
  Resource = 'Resource',
  Resources = 'Resources',
  ReviewTimesheets = 'Review Timesheets',
  RolesAndRules = 'Roles & Rules',
  ScheduledCollection = 'Scheduled',
  Search = 'Search',
  Services = 'Services',
  Settings = 'Settings',
  SettingsAndFilter = 'Settings & Filter',
  SetupSso = 'Setup SSO',
  SingleSignOn = 'Single Sign-on',
  SlackIntegration = 'Slack Integration',
  StoryPointSchemes = 'Story Point Schemes',
  SubFolders = 'Sub-Folders',
  TaskBoard = 'Board',
  Tasks = 'Tasks',
  TemplatesCollection = 'Templates',
  Time = 'Time',
  Timesheet = 'Timesheet',
  TimesheetReview = 'Timesheet Review',
  Timesheets = 'Timesheets',
  Today = 'Today',
  Trial = 'Trial',
  UltimateTrial = 'ULTIMATE Trial',
  Upgrade = 'Upgrade',
  WorkingTeams = 'Working Teams',
  Workload = 'Workload',
  Workspace = 'Workspace',
  WorkspaceAccess = 'Workspace Access',
  WorkspaceAll = 'All Workspaces',
  Workspaces = 'Workspaces',
  WorkspaceSettings = 'Workspace Settings',
}
