/* eslint-disable react/forbid-component-props */
import classNames from 'classnames';
import { ReactNode, SyntheticEvent } from 'react';
import {
  Button,
  DropdownItem,
  Header,
  IconProps,
  MenuItem,
  // eslint-disable-next-line no-restricted-imports
  Modal,
  SemanticCOLORS,
  SemanticShorthandItem,
} from 'semantic-ui-react';

import LpLink from 'containers/shared/lp_link';
import { LpFormikForm } from 'features/common/forms/formik/form';
import { LpModalProps, LpModalTransition } from 'features/common/modals/lp_modal';
import { LpTabbedModalProps } from 'features/common/modals/lp_tabbed_modal';

interface LpHeaderProps {
  children: ReactNode;
  className?: string;
  headerType: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  size?: 'tiny' | 'small' | 'medium' | 'large' | 'huge';
}

export const LpHeader = ({ children, className, headerType, size }: LpHeaderProps) => (
  <Header as={headerType} className={className} size={size}>
    {children}
  </Header>
);

export const LpButtonAsLink = ({
  children,
  className,
  color,
  disabled,
  onClick,
  openInNewWindow,
  primary,
  to,
  type,
  icon,
  content,
  e2eTestId,
  dataTestId,
}: {
  children?: ReactNode;
  className?: string;
  color?: SemanticCOLORS;
  disabled?: boolean;
  onClick?: (e: SyntheticEvent) => void;
  openInNewWindow?: boolean;
  primary?: boolean;
  to: string;
  type?: 'submit' | 'reset' | 'button';
  icon?: SemanticShorthandItem<IconProps>;
  content?: string;
  e2eTestId?: string;
  dataTestId?: string;
}) => {
  const newWindowProps = openInNewWindow
    ? {
        to: { pathname: to },
        target: '_blank',
        rel: 'noopener noreferrer nofollow',
      }
    : {};

  return (
    <Button
      as={LpLink}
      className={className}
      color={color}
      disabled={disabled}
      onClick={onClick}
      primary={primary}
      icon={icon}
      content={content}
      to={to}
      type={type}
      data-testid={dataTestId}
      data-e2e-test-id={e2eTestId}
      {...newWindowProps}
    >
      {children}
    </Button>
  );
};

// This component must be wrapped in a native html element (span) if used to trigger a Popup Component
export const LpMenuItemAsLink = ({
  active,
  children,
  className,
  fitted,
  href,
  to,
  onClick,
  e2eTestId,
  dataTestId,
}: {
  active?: boolean;
  children?: ReactNode;
  className?: string;
  fitted?: boolean;
  href?: string;
  to: string;
  onClick?: (e: SyntheticEvent) => void;
  e2eTestId?: string;
  dataTestId?: string;
}) => {
  return (
    <MenuItem
      as={LpLink}
      active={active}
      className={className}
      fitted={fitted}
      href={href}
      onClick={onClick}
      to={to}
      data-e2e-test-id={e2eTestId}
      data-testid={dataTestId}
    >
      {children}
    </MenuItem>
  );
};

export const LpDropdownItemAsLink = ({
  children,
  className,
  disabled,
  onClick,
  to,
}: {
  children?: ReactNode;
  className?: string;
  disabled?: boolean;
  onClick?: (e: SyntheticEvent) => void;
  to: string;
}) => {
  return (
    <DropdownItem as={LpLink} className={className} disabled={disabled} onClick={onClick} to={to}>
      {children}
    </DropdownItem>
  );
};

const portal = document.querySelector('#portal');

export const LpModalAsLpFormikForm = ({
  centered = false,
  className,
  closeOnDimmerClick = true,
  header,
  content,
  actions,
  onClose,
  size = 'small',
  closeOnEscape,
}: LpModalProps) => {
  return (
    <LpModalTransition>
      <Modal
        data-testid="lp-modal"
        mountNode={portal}
        className={classNames('lp-modal', className, {
          'lp-modal--centered': centered,
        })}
        closeOnDimmerClick={closeOnDimmerClick}
        open={true}
        size={size}
        onClose={onClose}
        closeOnEscape={closeOnEscape}
        as={LpFormikForm}
      >
        {header && <Modal.Header>{header}</Modal.Header>}
        {content && <Modal.Content>{content}</Modal.Content>}
        {actions && <Modal.Actions>{actions}</Modal.Actions>}
      </Modal>
    </LpModalTransition>
  );
};

export const LpTabbedModalAsLpFormikForm = ({ className, onClose, header, content, actions }: LpTabbedModalProps) => (
  <LpModalAsLpFormikForm
    className={classNames('tabbed-modal', className)}
    onClose={onClose}
    size="large"
    header={header}
    content={content}
    actions={actions}
  />
);
