import { useSelector } from 'react-redux';
import { Menu, MenuHeader } from 'semantic-ui-react';

import { ContactSupportLink } from 'features/common/buttons/contact_support_link';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import { LpIcon, signOutSolid, userCircleSolid, globeSolid as workspaceIcon } from 'features/common/lp_icon';
import { LpSvg, LpSvgs } from 'features/common/lp_svg';
import NewWindowLink from 'lib/display_helpers/url_links';
import { thirdPartyUrls, frontend } from 'lib/urls';
import { getIsGuestCurrentWorkspaceUser } from 'redux/entities/selectors/user';

import { SuggestedLessons } from './suggested_lessons';
import { UserMenuTableItem } from './table_item';

const UserMenuTableInfoItems = ({ userId, userName }: { userName: string; userId: number }) => {
  const workspaceId = useSelector(getCurrentWorkspaceId);
  const organizationId = useSelector(getCurrentOrganizationId);

  const isCurrentWorkspaceUserGuest = useSelector(getIsGuestCurrentWorkspaceUser);

  const myProfileLinkToProps = {
    userId,
    organizationId,
    workspaceId,
  };

  return (
    <>
      <MenuHeader className="user-menu__table-header" content={userName} />
      <UserMenuTableItem
        to={
          isCurrentWorkspaceUserGuest
            ? frontend.workspaceGuestProfile.url(myProfileLinkToProps)
            : frontend.member.url(myProfileLinkToProps)
        }
      >
        <LpIcon icon={userCircleSolid} />
        My Profile
      </UserMenuTableItem>

      {!isCurrentWorkspaceUserGuest && (
        <UserMenuTableItem to={frontend.organizationHub.url({ organizationId })}>
          <LpIcon icon={workspaceIcon} />
          Switch Workspace
        </UserMenuTableItem>
      )}

      <UserMenuTableItem to={frontend.logout.url({})} e2eTestId="logout-user-trigger-logout">
        <LpIcon icon={signOutSolid} />
        Logout
      </UserMenuTableItem>
    </>
  );
};

const UserMenuContactItems = () => {
  return (
    <>
      <Menu.Header className="user-menu__table-header" content={'Talk to us'} />

      <ContactSupportLink className="item user-menu__table-item" text={'Support & Feedback'} />

      <UserMenuTableItem to={thirdPartyUrls.productAdvisor} newWindow>
        Book Time With Us!
      </UserMenuTableItem>
    </>
  );
};

export const UserMenuTable = ({ userName, userId }: { userName: string; userId: number }) => (
  <Menu text vertical className="user-menu__table">
    <UserMenuTableInfoItems userName={userName} userId={userId} />
    <UserMenuContactItems />
    <SuggestedLessons />
    <NewWindowLink to={thirdPartyUrls.tempoIo}>
      <LpSvg icon={LpSvgs.TEMPO_LOGO} className="user-menu__table-logo" width={80} height={22} />
    </NewWindowLink>
  </Menu>
);
