import {
  WORK_HOURS_PER_DAY,
  MINUTES_PER_HOUR,
  SECONDS_IN_HOUR,
  SECONDS_PER_MINUTE,
  DAYS_IN_SECONDS,
  WORK_WEEKS_IN_SECONDS,
  WORK_MONTHS_IN_SECONDS,
  WORK_QUARTER_IN_SECONDS,
  WORK_YEARS_IN_SECONDS,
} from 'lib/constants';
import { roundAndTruncateTrailing } from 'lib/display_helpers/rounding';

export enum TimeUnit {
  Minutes = 'm',
  Hours = 'h',
  Days = 'd',
  Weeks = 'w',
  Months = 'mo',
  Quarter = 'q',
  Year = 'y',
}

export const timeUnitLookup: Record<string, TimeUnit> = {
  h: TimeUnit.Hours,
  H: TimeUnit.Hours,
  m: TimeUnit.Minutes,
  M: TimeUnit.Minutes,
  d: TimeUnit.Days,
  D: TimeUnit.Days,
};

export const wideRangeTimeUnitLookup: Record<string, TimeUnit> = {
  ...timeUnitLookup,
  w: TimeUnit.Weeks,
  W: TimeUnit.Weeks,
  mo: TimeUnit.Months,
  MO: TimeUnit.Months,
  q: TimeUnit.Quarter,
  Q: TimeUnit.Quarter,
  y: TimeUnit.Year,
  Y: TimeUnit.Year,
};

const minutesToHours = (value: number) => {
  const hours = roundAndTruncateTrailing(value / MINUTES_PER_HOUR);
  return String(hours);
};

const daysToHours = (value: number) => {
  const hours = roundAndTruncateTrailing(value * WORK_HOURS_PER_DAY);
  return String(hours);
};

const minutesToSeconds = (value: number) => {
  const seconds = value * SECONDS_PER_MINUTE;
  return String(seconds);
};

const hoursToSeconds = (value: number) => {
  const seconds = value * SECONDS_IN_HOUR;
  return String(seconds);
};

const daysToSeconds = (value: number) => {
  const seconds = value * DAYS_IN_SECONDS;
  return String(seconds);
};

const weeksToSeconds = (value: number) => {
  const seconds = value * WORK_WEEKS_IN_SECONDS;
  return String(seconds);
};

const monthToSeconds = (value: number) => {
  const seconds = value * WORK_MONTHS_IN_SECONDS;
  return String(seconds);
};

const quaterToSeconds = (value: number) => {
  const seconds = value * WORK_QUARTER_IN_SECONDS;
  return String(seconds);
};

const yearToSeconds = (value: number) => {
  const seconds = value * WORK_YEARS_IN_SECONDS;
  return String(seconds);
};

export const timeInputInHoursUSA = ({
  timeUnit,
  valueWithoutUnit,
}: {
  timeUnit: TimeUnit;
  valueWithoutUnit: string;
}): string => {
  switch (timeUnit) {
    case TimeUnit.Hours: {
      return valueWithoutUnit;
    }
    case TimeUnit.Minutes: {
      return minutesToHours(parseFloat(valueWithoutUnit));
    }
    case TimeUnit.Days: {
      return daysToHours(parseFloat(valueWithoutUnit));
    }
    default: {
      return valueWithoutUnit;
    }
  }
};

export const timeInputSeconds = ({
  timeUnit,
  valueWithoutUnit,
}: {
  timeUnit: TimeUnit;
  valueWithoutUnit: string;
}): string => {
  switch (timeUnit) {
    case TimeUnit.Minutes: {
      return minutesToSeconds(parseFloat(valueWithoutUnit));
    }
    case TimeUnit.Hours: {
      return hoursToSeconds(parseFloat(valueWithoutUnit));
    }
    case TimeUnit.Days: {
      return daysToSeconds(parseFloat(valueWithoutUnit));
    }
    case TimeUnit.Weeks: {
      return weeksToSeconds(parseFloat(valueWithoutUnit));
    }
    case TimeUnit.Months: {
      return monthToSeconds(parseFloat(valueWithoutUnit));
    }
    case TimeUnit.Quarter: {
      return quaterToSeconds(parseFloat(valueWithoutUnit));
    }
    case TimeUnit.Year: {
      return yearToSeconds(parseFloat(valueWithoutUnit));
    }
    default: {
      return hoursToSeconds(parseFloat(valueWithoutUnit));
    }
  }
};
