import LpLink from 'containers/shared/lp_link';
import { ContactSupportLink } from 'features/common/buttons/contact_support_link';
import { frontend } from 'lib/urls';

enum LoginErrors {
  InvalidAuthRequest = 'invalid_auth_request',
  InvalidAuthRequestSAML = 'lp_login_not_authorized_saml',
  InvalidEmail = 'invalid_email_address',
  InvalidUserNameOrPassword = 'invalid_username_or_password',
}

export const LoginError = ({ field }: { field: string }) => {
  switch (field) {
    case LoginErrors.InvalidAuthRequest: {
      return (
        <>
          <p>We&apos;re having trouble logging you in with SSO.</p>
          <p>Contact your SSO Administrator for help or try signing in with a different email.</p>
        </>
      );
    }
    case LoginErrors.InvalidAuthRequestSAML: {
      return (
        <>
          <p>We&apos;re having trouble signing you in.</p>
          <p>
            Try signing in with a different email or <LpLink to={frontend.loginSso.url({})}>sign in with SSO</LpLink>.
          </p>
        </>
      );
    }
    case LoginErrors.InvalidEmail:
    case LoginErrors.InvalidUserNameOrPassword: {
      return (
        <>
          <p>Incorrect username or password.</p>
          <p>
            Try again, use the <LpLink to={frontend.forgotPassword.url({})}>Forgot Password</LpLink> link, or{' '}
            <LpLink to={frontend.trial.url({})}>Sign up now</LpLink>.
          </p>
        </>
      );
    }
    default: {
      return (
        <>
          We&apos;ve encountered an unexpected error. <ContactSupportLink text="Contact support" /> for assistance.
        </>
      );
    }
  }
};
