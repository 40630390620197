import {
  timeInputSeconds,
  TimeUnit,
  timeUnitLookup,
  wideRangeTimeUnitLookup,
} from 'lib/conversion_helpers/time_input_in_hours_usa';
import {
  hoursInputValidationRegEx,
  hoursWideRangeInputValidationRegEx,
} from 'lib/validation_helpers/hours_input_validation_regex';

export const timeStringInSecondsFromTimeStringWithUnits = (decrementValue: string, allowWideRange = false) => {
  const NUMERIC_MATCH_INDEX = 1;
  const UNIT_MATCH_INDEX = 2;

  const regexp: RegExp = allowWideRange ? hoursWideRangeInputValidationRegEx : hoursInputValidationRegEx;
  const lookUpTable = allowWideRange ? wideRangeTimeUnitLookup : timeUnitLookup;

  const match = decrementValue.match(regexp);

  if (match) {
    const matchForUnit = match[UNIT_MATCH_INDEX] ?? '';
    const timeUnit = lookUpTable[matchForUnit] ?? TimeUnit.Hours;
    const valueWithoutUnit = match[NUMERIC_MATCH_INDEX];
    return valueWithoutUnit ? timeInputSeconds({ timeUnit, valueWithoutUnit }) : undefined;
  }
  return undefined;
};
