import { PmLegalTermsFooter } from 'containers/shared/footers/pm_legal_terms_footer';
import { UnauthenticatedHeader } from 'containers/shared/headers/unauthenticated_header';
import { UnauthenticatedTitles, PageNames } from 'containers/shared/headers/unauthenticated_titles';
import LpLink from 'containers/shared/lp_link';
import { frontend } from 'lib/urls';

import { SsoLoginCheckForm } from './login_sso_form';

import './login_sso.scss';

export const SsoLoginCheckPageV2 = () => {
  return (
    <div className="pm-login-sso">
      <UnauthenticatedHeader className="pm-login-sso__header" />
      <div className="pm-login-sso__steps">
        <div className="steps__back-action">
          <LpLink to={frontend.login.url({})}>← Back</LpLink>
        </div>
        <div className="steps__content">
          <UnauthenticatedTitles pageName={PageNames.LOGIN_SSO} />
          <SsoLoginCheckForm />
        </div>
      </div>
      <PmLegalTermsFooter className="pm-signup__footer" />
    </div>
  );
};
