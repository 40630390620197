import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { Button, Form } from 'semantic-ui-react';
import * as yup from 'yup';

import LpLink from 'containers/shared/lp_link';
import { UserDao } from 'daos/user';
import { LoginError } from 'features/authentication/unauthenticated/login_v2/login_errors';
import LpErrorMessage from 'features/common/errors/lp_error_message';
import LpFormInput from 'features/common/forms/lp_form_input';
import { getSessionStorageForKey, SessionStorageKey } from 'hooks/use_session_state';
import { awaitRequestFinish } from 'lib/api';
import { lpErrorText } from 'lib/helpers/yup/lp_error_text';
import { frontend } from 'lib/urls';
import { ProductName } from 'lib/use_product_name';

const schema = yup.object().shape({
  email: yup.string().trim().email(lpErrorText.email).required(lpErrorText.email),
});

export const SsoLoginCheckForm = () => {
  const dispatch = useDispatch();

  const {
    handleSubmit,
    isSubmitting,
    setSubmitting,
    getFieldMeta,
    getFieldProps,
    setStatus: setFormAPIError,
    status: formAPIError,
  } = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: schema,
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: ({ email }) => {
      setFormAPIError(undefined);

      const redirectLocationKey = getSessionStorageForKey(SessionStorageKey.RedirectLocation);
      const redirectLocation = redirectLocationKey?.pathname;
      const redirectHash = redirectLocationKey?.hash;

      const { uuid } = dispatch(
        UserDao.ssoLoginCheck({
          email,
          redirectLocation,
          redirectHash,
        })
      );

      dispatch(
        awaitRequestFinish<{ url: string }>(uuid, {
          onError: ({ errors }) => {
            if (errors[0]) {
              setFormAPIError(errors[0]);
            }

            setSubmitting(false);
          },
          onSuccess: ({ data }) => {
            window.location.assign(data.url);
          },
        })
      );
    },
  });

  const handleDismissApiError = () => setFormAPIError(undefined);

  return (
    <Form className="pm-login-sso__form" onSubmit={handleSubmit} loading={isSubmitting}>
      {formAPIError && (
        <LpErrorMessage
          className="login-sso__api-error"
          error={formAPIError}
          customError={<LoginError field={formAPIError.code} />}
          onDismiss={handleDismissApiError}
        />
      )}

      <LpFormInput
        disableLastPass={false}
        label="Business Email*"
        fieldKey="email"
        getFieldMeta={getFieldMeta}
        getFieldProps={getFieldProps}
        placeholder="Example@business.com"
      />

      <Button
        className="pm-login-sso__form-button pm-login-sso__form-button--full-width"
        type="submit"
        content={'Sign In →'}
        disabled={isSubmitting}
      />
      <div className="pm-login-sso__signup-cta">
        New to <ProductName />
        ?&nbsp;
        <LpLink to={frontend.trial.url({})}>Sign Up</LpLink>
      </div>
    </Form>
  );
};
