import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { Table } from 'semantic-ui-react';

import LpLink from 'containers/shared/lp_link';
import { planFamilyDisplayText, UserType } from 'daos/enums';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import {
  cactusSolid,
  creditCardSolid,
  robotSolid,
  folderClosedSolid,
  LpIcon,
  dotCircleSolid,
  globeSolid as workspaceIcon,
  idCardClipLight,
  userPlusSolid,
  fileSolid,
  passportSolid,
} from 'features/common/lp_icon';
import { NavigationMenuDisplay } from 'features/workspace/navigation_menu_display';
import { useFetchWorkspaceUserCountsOnOrganizationModified } from 'hooks/use_workspace_user_count_fetch_on_organization_modified';
import NewWindowLink from 'lib/display_helpers/url_links';
import { convertMBtoBytes, formatFileSizeFromBytes } from 'lib/helpers';
import { thirdPartyUrls, frontend } from 'lib/urls';
import { getCurrentOrganization } from 'redux/entities/selectors/organization';
import { getCurrentOrganizationPlan } from 'redux/entities/selectors/plan';
import { getCurrentOrganizationUser } from 'redux/entities/selectors/user';
import { getCurrentWorkspace } from 'redux/entities/selectors/workspace';
import { slate600 } from 'style/variables';

import './plan_gadget.scss';

export const PlanGadgetSidebar = ({
  includeWorkspace,
  includeContactSupport,
}: {
  includeWorkspace?: boolean;
  includeContactSupport?: boolean;
}) => (
  <aside className="plan-gadget__sidebar">
    <PlanGadget includeWorkspace={includeWorkspace} />
    {includeContactSupport && (
      <p>
        Need help? For questions on
        <br />
        plans and setup <NewWindowLink to={thirdPartyUrls.productAdvisor}>Contact Us</NewWindowLink>
      </p>
    )}
  </aside>
);

export function PlanGadget({ includeWorkspace }: { includeWorkspace?: boolean }) {
  const organizationId = useSelector(getCurrentOrganizationId);
  const organization = useSelector(getCurrentOrganization);
  const workspaceId = useSelector(getCurrentWorkspaceId);
  const workspace = useSelector(getCurrentWorkspace);
  const planFamily = useSelector(getCurrentOrganizationPlan)?.family;
  const isAdmin = useSelector(getCurrentOrganizationUser)?.admin;

  const isLicensedUsersTab = useRouteMatch(frontend.organizationHubUsers.url({ organizationId }))?.isExact;
  const isPendingInvitesTab = useRouteMatch(
    frontend.organizationHubUsersPendingInvites.url({ organizationId })
  )?.isExact;
  const { workspaceUserCounts } = useFetchWorkspaceUserCountsOnOrganizationModified();

  if (!organization || !workspace || !planFamily) {
    return null;
  }

  const {
    fileLimitInGb,
    licensesUsed,
    maxProjectCount,
    maxResourceCount,
    maxTaskCount,
    maxWorkspaceCount,
    licensesPending,
    projectCount,
    sumFileSizeInMb,
    taskCount,
    totalLicenses,
    workspaceCount,
  } = organization.flags;

  const planName = planFamilyDisplayText[planFamily];

  return (
    <>
      <Table className="plan-gadget">
        <Table.Body>
          <Table.Row className="plan-gadget__header">
            <Table.Cell colSpan={3}>{organization.name}</Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell className="plan-gadget__icon-cell">
              <LpIcon icon={creditCardSolid} color={slate600} />
            </Table.Cell>
            <Table.Cell className="plan-gadget__description-cell">Plan</Table.Cell>
            <Table.Cell>
              {isAdmin ? (
                <LpLink to={frontend.organizationHubManageAccount.url({ organizationId })}>{planName}</LpLink>
              ) : (
                planName
              )}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell className="plan-gadget__icon-cell">
              <LpIcon icon={idCardClipLight} color={slate600} />
            </Table.Cell>
            <Table.Cell className="plan-gadget__description-cell">
              {isAdmin && !isLicensedUsersTab ? (
                <LpLink to={frontend.organizationHubUsers.url({ organizationId })}>
                  {NavigationMenuDisplay.LicensedUsers}
                </LpLink>
              ) : (
                NavigationMenuDisplay.LicensedUsers
              )}
            </Table.Cell>
            <Table.Cell>
              {licensesUsed} / {totalLicenses}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell className="plan-gadget__icon-cell">
              <LpIcon icon={userPlusSolid} color={slate600} />
            </Table.Cell>
            <Table.Cell className="plan-gadget__description-cell">
              {isAdmin && !isPendingInvitesTab ? (
                <LpLink to={frontend.organizationHubUsersPendingInvites.url({ organizationId })}>
                  {NavigationMenuDisplay.PendingUsers}
                </LpLink>
              ) : (
                NavigationMenuDisplay.PendingUsers
              )}
            </Table.Cell>
            <Table.Cell>{licensesPending}</Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell className="plan-gadget__icon-cell">
              <LpIcon icon={workspaceIcon} color={slate600} />
            </Table.Cell>
            <Table.Cell className="plan-gadget__description-cell">Workspaces</Table.Cell>
            <Table.Cell>
              {workspaceCount} / {maxWorkspaceCount ?? 'Unlimited'}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell className="plan-gadget__icon-cell">
              <LpIcon icon={folderClosedSolid} color={slate600} />
            </Table.Cell>
            <Table.Cell className="plan-gadget__description-cell">Projects </Table.Cell>
            <Table.Cell>
              {projectCount} / {maxProjectCount ?? 'Unlimited'}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell className="plan-gadget__icon-cell">
              <LpIcon icon={dotCircleSolid} color={slate600} />
            </Table.Cell>
            <Table.Cell className="plan-gadget__description-cell">Tasks</Table.Cell>
            <Table.Cell>
              {taskCount} / {maxTaskCount ?? 'Unlimited'}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell className="plan-gadget__icon-cell">
              <LpIcon icon={fileSolid} color={slate600} />
            </Table.Cell>
            <Table.Cell className="plan-gadget__description-cell">File Storage</Table.Cell>
            <Table.Cell>
              <>
                {formatFileSizeFromBytes(convertMBtoBytes(sumFileSizeInMb ?? 0))} / {fileLimitInGb ?? 'Unlimited'} GB
              </>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      {includeWorkspace && (
        <>
          <Table className="plan-gadget">
            <Table.Body>
              <Table.Row className="plan-gadget__header">
                <Table.Cell colSpan={3}>{workspace.name}</Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell className="plan-gadget__icon-cell">
                  <LpIcon icon={userPlusSolid} color={slate600} />
                </Table.Cell>
                <Table.Cell className="plan-gadget__description-cell">
                  <LpLink
                    to={`${frontend.people.url({
                      organizationId,
                      workspaceId,
                    })}?profileType=${UserType.Member}`}
                  >
                    Members
                  </LpLink>
                </Table.Cell>
                <Table.Cell>
                  {workspaceUserCounts.member} / {totalLicenses}
                </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell className="plan-gadget__icon-cell">
                  <LpIcon icon={robotSolid} color={slate600} />
                </Table.Cell>
                <Table.Cell className="plan-gadget__description-cell">
                  <LpLink
                    to={`${frontend.people.url({
                      organizationId,
                      workspaceId,
                    })}?profileType=${UserType.Resource}`}
                  >
                    Resources
                  </LpLink>
                </Table.Cell>
                <Table.Cell>
                  {workspaceUserCounts.resource} / {maxResourceCount}
                </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell className="plan-gadget__icon-cell">
                  <LpIcon icon={cactusSolid} color={slate600} />
                </Table.Cell>
                <Table.Cell className="plan-gadget__description-cell">
                  <LpLink
                    to={`${frontend.people.url({
                      organizationId,
                      workspaceId,
                    })}?profileType=${UserType.Placeholder}`}
                  >
                    Placeholders
                  </LpLink>
                </Table.Cell>
                <Table.Cell>{workspaceUserCounts.placeholder} / Unlimited</Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell className="plan-gadget__icon-cell">
                  <LpIcon icon={passportSolid} color={slate600} />
                </Table.Cell>
                <Table.Cell className="plan-gadget__description-cell">
                  <LpLink
                    to={`${frontend.people.url({
                      organizationId,
                      workspaceId,
                    })}?profileType=dashboard_guests`}
                  >
                    Guests
                  </LpLink>
                </Table.Cell>
                <Table.Cell>{workspaceUserCounts.guest} / Unlimited</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </>
      )}
    </>
  );
}
