import LpLink from 'containers/shared/lp_link';
import { ContactSupportLink } from 'features/common/buttons/contact_support_link';
import { frontend } from 'lib/urls';

enum ForgotPasswordErrorsField {
  InvalidEmail = 'record_not_found',
}

export const ForgotPasswordErrors = ({ field }: { field: string }) => {
  switch (field) {
    case ForgotPasswordErrorsField.InvalidEmail: {
      return (
        <p>
          This email is not in our system. Try again, or <LpLink to={frontend.trial.url({})}>{'Sign up now'}</LpLink>.
        </p>
      );
    }
    default: {
      return <ContactSupportLink text="Contact support" />;
    }
  }
};
