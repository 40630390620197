import { FavoriteViewType, PackageStatus, UserType } from 'daos/enums';
import { Favorite, Group, Item, LibraryResource, OrganizationUser, User } from 'daos/model_types';
import { packageStatusDisplayNameByPackageStatus } from 'daos/pkg';
import { NavigationMenuDisplay } from 'features/workspace/navigation_menu_display';
import { UnreachableCaseError } from 'lib/unreachable_case_error';
import { frontend } from 'lib/urls';
import { gray400, slate200, teal500 } from 'style/variables';

import { FavoritesUrlProps } from './types';

export const favoriteUrl = ({
  organizationId,
  workspaceId,
  favorite,
  favoriteUserUserType,
  libraryResource,
}: {
  organizationId: number;
  workspaceId: number;
  favorite: Favorite;
  itemId?: number;
  packageStatus: PackageStatus | null | undefined;
  favoriteUserUserType: UserType | undefined;
  libraryResource: LibraryResource | undefined;
}) => {
  const { group, item, user, view } = favorite;

  switch (view) {
    case FavoriteViewType.LibraryResource: {
      if (libraryResource?.dashboardId) {
        return frontend.portableDashboard.url({
          organizationId,
          workspaceId,
          dashboardId: libraryResource.dashboardId,
        });
      }
      if (libraryResource?.widgetId) {
        return frontend.portableReport.url({ organizationId, workspaceId, widgetId: libraryResource.widgetId });
      }
      return undefined;
    }

    case FavoriteViewType.PortfolioAll:
      return frontend.projectsAll.url({ organizationId, workspaceId });
    case FavoriteViewType.PortfolioScheduled:
      return frontend.scheduledCollectionPortfolio.url({ organizationId, workspaceId });
    case FavoriteViewType.PortfolioPending:
      return frontend.pendingCollection.url({ organizationId, workspaceId });
    case FavoriteViewType.PortfolioArchived:
      return frontend.archiveCollection.url({ organizationId, workspaceId });
    case FavoriteViewType.PortfolioCapacity:
      return frontend.capacityCollection.url({ organizationId, workspaceId });
    case FavoriteViewType.PortfolioCapacityAllocation:
      return frontend.capacityCollectionCapacity.url({ organizationId, workspaceId });
    case FavoriteViewType.PortfolioScheduledChanges:
      return frontend.scheduledCollectionChanges.url({ organizationId, workspaceId });
    case FavoriteViewType.PortfolioPendingChanges:
      return frontend.pendingCollectionChanges.url({ organizationId, workspaceId });
    case FavoriteViewType.PortfolioArchivedChanges:
      return frontend.archiveCollectionChanges.url({ organizationId, workspaceId });
    case FavoriteViewType.PortfolioScheduledGrid:
      return frontend.scheduledCollectionGrid.url({ organizationId, workspaceId });
    case FavoriteViewType.PortfolioPendingGrid:
      return frontend.pendingCollectionGrid.url({ organizationId, workspaceId });
    case FavoriteViewType.PortfolioArchivedGrid:
      return frontend.archiveCollectionGrid.url({ organizationId, workspaceId });
    case FavoriteViewType.PortfolioScheduledSearch:
      return frontend.scheduledCollectionSearch.url({ organizationId, workspaceId });
    case FavoriteViewType.PortfolioPendingSearch:
      return frontend.pendingCollectionSearch.url({ organizationId, workspaceId });
    case FavoriteViewType.PortfolioArchivedSearch:
      return frontend.archiveCollectionSearch.url({ organizationId, workspaceId });
    case FavoriteViewType.PortfolioScheduledWorkload:
      return frontend.scheduledCollectionWorkload.url({ organizationId, workspaceId });
    case FavoriteViewType.PortfolioScheduledLibrary:
      return frontend.scheduledCollectionLibrary.url({ organizationId, workspaceId });
    case FavoriteViewType.PortfolioPendingLibrary:
      return frontend.pendingCollectionLibrary.url({ organizationId, workspaceId });
    case FavoriteViewType.PortfolioArchivedLibrary:
      return frontend.archiveCollectionLibrary.url({ organizationId, workspaceId });

    case FavoriteViewType.Package:
      return item ? frontend.singlePackage.url({ organizationId, workspaceId, packageId: item.id }) : undefined;
    case FavoriteViewType.PackageChanges:
      return item ? frontend.packageChanges.url({ organizationId, workspaceId, packageId: item.id }) : undefined;
    case FavoriteViewType.PackageGrid:
      return item ? frontend.packageGrid.url({ organizationId, workspaceId, packageId: item.id }) : undefined;
    case FavoriteViewType.PackageSearch:
      return item ? frontend.packageSearch.url({ organizationId, workspaceId, packageId: item.id }) : undefined;
    case FavoriteViewType.PackageWorkload:
      return item ? frontend.packageWorkload.url({ organizationId, workspaceId, packageId: item.id }) : undefined;
    case FavoriteViewType.PackageBoard:
      return item ? frontend.packageTaskBoard.url({ organizationId, workspaceId, packageId: item.id }) : undefined;
    case FavoriteViewType.PackageLibrary:
      return item ? frontend.packageLibrary.url({ organizationId, workspaceId, packageId: item.id }) : undefined;
    case FavoriteViewType.PackageAllocation:
      return item ? frontend.packageAllocation.url({ organizationId, workspaceId, packageId: item.id }) : undefined;

    case FavoriteViewType.Project:
      return item ? frontend.projectProject.url({ organizationId, workspaceId, itemId: item.id }) : undefined;
    case FavoriteViewType.ProjectLibrary:
      return item ? frontend.projectLibrary.url({ organizationId, workspaceId, itemId: item.id }) : undefined;
    case FavoriteViewType.ProjectChanges:
      return item ? frontend.projectChanges.url({ organizationId, workspaceId, itemId: item.id }) : undefined;
    case FavoriteViewType.ProjectBoard:
      return item ? frontend.projectBoard.url({ organizationId, workspaceId, itemId: item.id }) : undefined;
    case FavoriteViewType.ProjectGrid:
      return item ? frontend.projectGrid.url({ organizationId, workspaceId, itemId: item.id }) : undefined;
    case FavoriteViewType.ProjectWorkload:
      return item ? frontend.projectWorkload.url({ organizationId, workspaceId, itemId: item.id }) : undefined;
    case FavoriteViewType.ProjectSearch:
      return item ? frontend.projectSearch.url({ organizationId, workspaceId, itemId: item.id }) : undefined;
    case FavoriteViewType.ProjectAllocation:
      return item ? frontend.projectAllocation.url({ organizationId, workspaceId, itemId: item.id }) : undefined;

    case FavoriteViewType.Profiles:
      return frontend.people.url({ organizationId, workspaceId });
    case FavoriteViewType.Groups:
      return frontend.groups.url({ organizationId, workspaceId });
    case FavoriteViewType.Teams:
      return frontend.teams.url({ organizationId, workspaceId });
    case FavoriteViewType.MembersAvailability:
      return frontend.membersAvailability.url({ organizationId, workspaceId });
    case FavoriteViewType.TimesheetReview: {
      if (group?.id) {
        return frontend.groupTime.url({ organizationId, workspaceId, groupId: group.id });
      }

      if (item?.id) {
        return frontend.projectTime.url({ organizationId, workspaceId, itemId: item.id });
      }

      return frontend.time.url({ organizationId, workspaceId });
    }
    case FavoriteViewType.TimesheetExport:
      return frontend.timesheetExport.url({ organizationId, workspaceId });

    case FavoriteViewType.MemberChanges:
      return user ? frontend.memberChangeLog.url({ organizationId, workspaceId, userId: user.id }) : undefined;
    case FavoriteViewType.MemberTasks:
      return user ? frontend.memberWorkload.url({ organizationId, workspaceId, userId: user.id }) : undefined;
    case FavoriteViewType.MemberBoard:
      return user ? frontend.memberTaskBoard.url({ organizationId, workspaceId, userId: user.id }) : undefined;
    case FavoriteViewType.MemberAvailability:
      return user ? frontend.memberAvailability.url({ organizationId, workspaceId, userId: user.id }) : undefined;
    case FavoriteViewType.MemberTimesheet:
      return user ? frontend.memberTimesheet.url({ organizationId, workspaceId, userId: user.id }) : undefined;
    case FavoriteViewType.MemberSearch:
      return user ? frontend.memberSearch.url({ organizationId, workspaceId, userId: user.id }) : undefined;
    case FavoriteViewType.MemberAccess:
      return user ? frontend.memberProjectAccess.url({ organizationId, workspaceId, userId: user.id }) : undefined;
    case FavoriteViewType.MemberProfile:
      if (user) {
        if (favoriteUserUserType === UserType.Resource) {
          return frontend.resource.url({ organizationId, workspaceId, userId: user.id });
        }
        return frontend.member.url({ organizationId, workspaceId, userId: user.id });
      }
      return undefined;

    case FavoriteViewType.MyWorkload:
      return frontend.myWork.url({ organizationId, workspaceId });
    case FavoriteViewType.MyBoard:
      return frontend.myWorkTaskBoard.url({ organizationId, workspaceId });
    case FavoriteViewType.MyTimesheet:
      return frontend.myWorkTimesheet.url({ organizationId, workspaceId });
    case FavoriteViewType.MyAvailability:
      return frontend.myWorkAvailability.url({ organizationId, workspaceId });
    case FavoriteViewType.MySearch:
      return frontend.myWorkSearch.url({ organizationId, workspaceId });
    case FavoriteViewType.MyChanges:
      return frontend.myWorkChanges.url({ organizationId, workspaceId });
    case FavoriteViewType.MyAccess:
      return frontend.myWorkProjectAccess.url({ organizationId, workspaceId });
    case FavoriteViewType.MyProfile:
      return frontend.myWorkProfile.url({ organizationId, workspaceId });

    case FavoriteViewType.Group:
      return group ? frontend.group.url({ organizationId, workspaceId, groupId: group.id }) : undefined;
    case FavoriteViewType.GroupTasks:
      return group ? frontend.groupWorkload.url({ organizationId, workspaceId, groupId: group.id }) : undefined;
    case FavoriteViewType.GroupBoard:
      return group ? frontend.groupTaskBoard.url({ organizationId, workspaceId, groupId: group.id }) : undefined;
    case FavoriteViewType.GroupAccess:
      return group ? frontend.groupProjectAccess.url({ organizationId, workspaceId, groupId: group.id }) : undefined;

    case FavoriteViewType.Team:
      return group ? frontend.team.url({ organizationId, workspaceId, groupId: group.id }) : undefined;
    case FavoriteViewType.TeamBoard:
      return group ? frontend.teamTaskBoard.url({ organizationId, workspaceId, groupId: group.id }) : undefined;
    case FavoriteViewType.TeamProjects:
      return group ? frontend.teamProjects.url({ organizationId, workspaceId, groupId: group.id }) : undefined;
    case FavoriteViewType.TeamTime:
      return group ? frontend.teamTime.url({ organizationId, workspaceId, groupId: group.id }) : undefined;
    case FavoriteViewType.TeamWorkload:
      return group ? frontend.teamWorkload.url({ organizationId, workspaceId, groupId: group.id }) : undefined;
    default:
      throw new UnreachableCaseError(view);
  }
};

export const geFavoriteUrlProps = ({
  view,
  organizationId,
  workspaceId,
  libraryResourceId,
  itemId,
  groupId,
  userId,
}: {
  view: FavoriteViewType;
  organizationId: number;
  workspaceId: number;
  dashboardId?: number;
  libraryResourceId?: number;
  itemId?: number;
  groupId?: number;
  userId?: number;
  packageStatus?: PackageStatus | null;
}): FavoritesUrlProps => {
  switch (view) {
    case FavoriteViewType.Group:
    case FavoriteViewType.GroupAccess:
    case FavoriteViewType.GroupBoard:
    case FavoriteViewType.GroupTasks:
    case FavoriteViewType.Team:
    case FavoriteViewType.TeamBoard:
    case FavoriteViewType.TeamProjects:
    case FavoriteViewType.TeamTime:
    case FavoriteViewType.TeamWorkload:
      return { organizationId, workspaceId, groupId };
    case FavoriteViewType.MemberAccess:
    case FavoriteViewType.MemberAvailability:
    case FavoriteViewType.MemberBoard:
    case FavoriteViewType.MemberChanges:
    case FavoriteViewType.MemberProfile:
    case FavoriteViewType.MemberSearch:
    case FavoriteViewType.MemberTasks:
    case FavoriteViewType.MemberTimesheet:
    case FavoriteViewType.MembersAvailability:
      return { organizationId, workspaceId, userId };
    case FavoriteViewType.Package:
    case FavoriteViewType.PackageAllocation:
    case FavoriteViewType.PackageBoard:
    case FavoriteViewType.PackageChanges:
    case FavoriteViewType.PackageGrid:
    case FavoriteViewType.PackageLibrary:
    case FavoriteViewType.PackageSearch:
    case FavoriteViewType.PackageWorkload:
    case FavoriteViewType.Project:
    case FavoriteViewType.ProjectAllocation:
    case FavoriteViewType.ProjectBoard:
    case FavoriteViewType.ProjectChanges:
    case FavoriteViewType.ProjectGrid:
    case FavoriteViewType.ProjectLibrary:
    case FavoriteViewType.ProjectSearch:
    case FavoriteViewType.ProjectWorkload:
      return { organizationId, workspaceId, itemId };
    case FavoriteViewType.PortfolioArchived:
    case FavoriteViewType.PortfolioArchivedChanges:
    case FavoriteViewType.PortfolioArchivedGrid:
    case FavoriteViewType.PortfolioArchivedLibrary:
    case FavoriteViewType.PortfolioArchivedSearch:
      return { organizationId, workspaceId, itemId, packageStatus: PackageStatus.ARCHIVED };
    case FavoriteViewType.PortfolioCapacityAllocation:
    case FavoriteViewType.PortfolioPending:
    case FavoriteViewType.PortfolioPendingChanges:
    case FavoriteViewType.PortfolioPendingGrid:
    case FavoriteViewType.PortfolioPendingLibrary:
    case FavoriteViewType.PortfolioPendingSearch:
      return { organizationId, workspaceId, itemId, packageStatus: PackageStatus.BACKLOG };
    case FavoriteViewType.PortfolioCapacity:
      return { organizationId, workspaceId, itemId, packageStatus: PackageStatus.CAPACITY };
    case FavoriteViewType.PortfolioScheduled:
    case FavoriteViewType.PortfolioScheduledChanges:
    case FavoriteViewType.PortfolioScheduledGrid:
    case FavoriteViewType.PortfolioScheduledLibrary:
    case FavoriteViewType.PortfolioScheduledSearch:
    case FavoriteViewType.PortfolioScheduledWorkload:
      return { organizationId, workspaceId, itemId, packageStatus: PackageStatus.SCHEDULED };
    case FavoriteViewType.PortfolioAll:
    case FavoriteViewType.Profiles:
    case FavoriteViewType.Groups:
    case FavoriteViewType.MySearch:
    case FavoriteViewType.MyAccess:
    case FavoriteViewType.MyAvailability:
    case FavoriteViewType.MyBoard:
    case FavoriteViewType.MyChanges:
    case FavoriteViewType.MyProfile:
    case FavoriteViewType.MyTimesheet:
    case FavoriteViewType.MyWorkload:
    case FavoriteViewType.Teams:
    case FavoriteViewType.TimesheetExport:
      return { organizationId, workspaceId };
    case FavoriteViewType.TimesheetReview: {
      if (groupId) {
        return { organizationId, workspaceId, groupId };
      }
      if (itemId) {
        return { organizationId, workspaceId, itemId };
      }
      return { organizationId, workspaceId };
    }
    case FavoriteViewType.LibraryResource: {
      return { organizationId, workspaceId, itemId, libraryResourceId };
    }
  }
};

export const favoriteNameFromViewType = (viewType: FavoriteViewType | undefined, hasTeamBasedPlanning?: boolean) => {
  switch (viewType) {
    // People views
    case FavoriteViewType.Profiles:
      return NavigationMenuDisplay.Profiles;
    case FavoriteViewType.Groups: {
      if (hasTeamBasedPlanning) {
        return NavigationMenuDisplay.AdministrativeGroups;
      }
      return NavigationMenuDisplay.Groups;
    }
    case FavoriteViewType.Teams:
      return NavigationMenuDisplay.WorkingTeams;
    case FavoriteViewType.MembersAvailability:
      return NavigationMenuDisplay.MembersAvailability;
    case FavoriteViewType.TimesheetReview:
    case FavoriteViewType.TimesheetExport:
      return NavigationMenuDisplay.Time;

    // MyWork views
    case FavoriteViewType.MyAccess:
      return NavigationMenuDisplay.Access;
    case FavoriteViewType.MyWorkload:
      return NavigationMenuDisplay.Workload;
    case FavoriteViewType.MyBoard:
      return NavigationMenuDisplay.TaskBoard;
    case FavoriteViewType.MyTimesheet:
      return NavigationMenuDisplay.Timesheet;
    case FavoriteViewType.MyAvailability:
      return NavigationMenuDisplay.Availability;
    case FavoriteViewType.MySearch:
      return NavigationMenuDisplay.Search;
    case FavoriteViewType.MyChanges:
      return NavigationMenuDisplay.Changes;
    case FavoriteViewType.MyProfile:
      return NavigationMenuDisplay.Profile;

    case FavoriteViewType.PortfolioAll:
      return NavigationMenuDisplay.PortfolioAll;

    case FavoriteViewType.PortfolioScheduledLibrary:
    case FavoriteViewType.PortfolioPendingLibrary:
    case FavoriteViewType.PortfolioArchivedLibrary:
    case FavoriteViewType.PackageLibrary:
    case FavoriteViewType.ProjectLibrary:
      return NavigationMenuDisplay.Library;

    default:
      return '';
  }
};

const removeStyleVariableHashtag = (styleVariable: string) => styleVariable.substring(1);

const peopleViews = new Set([
  FavoriteViewType.Profiles,
  FavoriteViewType.Groups,
  FavoriteViewType.TimesheetReview,
  FavoriteViewType.TimesheetExport,
]);

export const getFavoriteNameAndColor = ({
  libraryResource,
  item,
  group,
  user,
  orgUser,
  packageStatus,
  favoriteViewType,
  hasTeamBasedPlanning,
}: {
  libraryResource: LibraryResource | undefined;
  item: Item | undefined;
  group: Group | undefined;
  user: User | undefined;
  orgUser: OrganizationUser | undefined;
  packageStatus: PackageStatus | null | undefined;
  favoriteViewType: FavoriteViewType | undefined;
  hasTeamBasedPlanning?: boolean;
}) => {
  const packageStatusDisplayName = packageStatus ? packageStatusDisplayNameByPackageStatus[packageStatus] : null;
  const isMember = user && user.userType === UserType.Member;

  const favoriteName =
    libraryResource?.name ??
    item?.name ??
    group?.name ??
    (user && user.firstName + ' ' + user.lastName) ??
    packageStatusDisplayName ??
    favoriteNameFromViewType(favoriteViewType, hasTeamBasedPlanning);

  const favoriteColor =
    libraryResource?.color ??
    item?.color ??
    group?.color ??
    (isMember ? orgUser?.avatarColor ?? removeStyleVariableHashtag(teal500) : null) ??
    (!isMember ? orgUser?.avatarColor : null) ??
    (favoriteViewType && peopleViews.has(favoriteViewType) ? removeStyleVariableHashtag(teal500) : null) ??
    (packageStatusDisplayName ? removeStyleVariableHashtag(gray400) : removeStyleVariableHashtag(slate200));

  return { favoriteColor, favoriteName };
};
