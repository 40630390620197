import classNames from 'classnames';

import NewWindowLink from 'lib/display_helpers/url_links';
import { thirdPartyUrls } from 'lib/urls';

import './pm_legal_terms_footer.scss';

export const PmLegalTermsFooter = ({ className }: { className?: string }) => (
  <p className={classNames(className, 'pm-legal-terms-footer')}>
    By choosing to continue, you agree to our{' '}
    <NewWindowLink className="pm-legal-terms-footer__link" to={thirdPartyUrls.termsOfService}>
      Terms of Service
    </NewWindowLink>{' '}
    and{' '}
    <NewWindowLink className="pm-legal-terms-footer__link" to={thirdPartyUrls.privacyPolicy}>
      Privacy Policy
    </NewWindowLink>
  </p>
);
