import { Dispatch, SetStateAction } from 'react';
import { Route, Switch } from 'react-router';

import { EntityTypeNotFound } from 'features/authentication/authenticated/errors/entity_not_found';
import { useFrontloadData } from 'features/authentication/hooks/use_frontload_data';
import { UnauthenticatedLayout } from 'features/authentication/unauthenticated/layout';
import { EmailConfirmation } from 'features/authentication/unauthenticated/token/email_confirmation';
import { AcceptInvitation } from 'features/authentication/unauthenticated/token/invitation';
import ResetPassword from 'features/authentication/unauthenticated/token/reset_password';
import { SetPassword } from 'features/authentication/unauthenticated/token/set_password';
import { frontend } from 'lib/urls';

interface TokenRoutesProps {
  token: string;
  setSelectedOrgId: Dispatch<SetStateAction<number | undefined>>;
  setSelectedWsId: Dispatch<SetStateAction<number | undefined>>;
  setEntityNotFound: Dispatch<SetStateAction<EntityTypeNotFound | undefined>>;
}

export const TokenRoutes = ({ token, setSelectedOrgId, setSelectedWsId, setEntityNotFound }: TokenRoutesProps) => {
  const frontloadData = useFrontloadData({ setSelectedOrgId, setSelectedWsId, setEntityNotFound });

  return (
    <UnauthenticatedLayout>
      <Switch>
        <Route path={frontend.emailConfirmation.pattern} render={() => <EmailConfirmation token={token} />} />
        <Route
          path={frontend.invitationAccept.pattern}
          render={() => <AcceptInvitation frontloadData={frontloadData} token={token} />}
        />
        <Route path={frontend.setPassword.pattern} render={() => <SetPassword token={token} />} />
        <Route path={frontend.resetPassword.pattern} render={() => <ResetPassword token={token} />} />
      </Switch>
    </UnauthenticatedLayout>
  );
};
