import NewWindowLink from 'lib/display_helpers/url_links';
import { thirdPartyUrls } from 'lib/urls';

export const TermsOfServiceLabel = () => {
  return (
    <label htmlFor="tosCheckbox">
      I agree to the <NewWindowLink to={thirdPartyUrls.termsOfService}>Terms of Service</NewWindowLink> and{' '}
      <NewWindowLink to={thirdPartyUrls.privacyPolicy}>Privacy Policy</NewWindowLink>
    </label>
  );
};
