export const NovaEmptyPlansSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="120" height="138" viewBox="0 0 120 138" fill="none">
      <path d="M119.755 105.698H38.3145V0H93.5251L119.755 26.2298V105.698Z" fill="#A7FFFF" />
      <path d="M120 26.2296H93.7639V0" fill="#69F6FF" />
      <path d="M81.4405 137.668H0V31.9702H55.2108L81.4405 58.2063V137.668Z" fill="#F9B6B0" />
      <path
        d="M91.1696 123.599C88.0668 117.884 85.2842 112.846 82.5959 107.759C81.7982 106.251 80.7556 105.183 79.2419 104.373C74.1605 101.647 69.1356 98.8146 63.5266 95.7054C67.8857 93.2621 71.7296 90.7684 75.85 88.8904C79.983 87.0061 82.6462 84.1229 84.5242 80.0653C86.4023 76.0077 88.7701 72.1828 91.1883 67.7798C94.2347 73.3762 97.0298 78.3948 99.7055 83.4699C100.566 85.103 101.709 86.2399 103.33 87.1067C108.392 89.8201 113.405 92.6465 118.857 95.6677C113.386 98.714 108.399 101.553 103.336 104.254C101.703 105.121 100.572 106.245 99.7181 107.872C97.0298 112.972 94.2222 118.009 91.1759 123.606"
        fill="#1D1D1B"
      />
      <path d="M81.4405 58.2063H55.2107V31.9702" fill="#FF8373" />
    </svg>
  );
};
