import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { getCurrentOrganizationId } from 'features/common/current/selectors';
import NewWindowLink from 'lib/display_helpers/url_links';

export const getTempoSupportUrl = (orgId?: number) => {
  return orgId
    ? `https://tempo-io.atlassian.net/servicedesk/customer/portal/6/group/1051/create/1410?customfield_12777=${orgId}`
    : 'https://tempo-io.atlassian.net/servicedesk/customer/portal/6/group/1051/create/1410';
};

export const ContactSupportLink = ({ className, text }: { className?: string; text: string }) => {
  const organizationId = useSelector(getCurrentOrganizationId);

  return (
    <NewWindowLink className={classNames('contact-support-link', className)} to={getTempoSupportUrl(organizationId)}>
      {text}
    </NewWindowLink>
  );
};
